import { Typography } from '@mui/material';
import { firstLetterUppercase } from 'utils/commonHelpers';

export const getChipAccount = (status: string) => {
  if (status === 'active') {
    return (
      <Typography component="span" className="chip active">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }
  if (status === 'disabled') {
    return (
      <Typography component="span" className="chip disabled">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  return (
    <Typography component="span" className="chip disabled">
      Disabled
    </Typography>
  );
};

export const getChipReview = (status: string) => {
  if (status === 'visible') {
    return (
      <Typography component="span" className="chip visible">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }
  if (status === 'hidden') {
    return (
      <Typography component="span" className="chip hidden">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  return (
    <Typography component="span" className="chip hidden">
      Hidden
    </Typography>
  );
};

export const getAppointmentStatus = (status: string) => {
  if (status === 'booked') {
    return (
      <Typography component="span" className="chip booked">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'cancelled') {
    return (
      <Typography component="span" className="chip cancelled">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'finished') {
    return (
      <Typography component="span" className="chip finished">
        Completed
      </Typography>
    );
  }
  return (
    <Typography component="span" className="chip booked">
      {firstLetterUppercase(status)}
    </Typography>
  );
};

export const getTransactionStatus = (status: string) => {
  if (status === 'completed') {
    return (
      <Typography component="span" className="chip completed-transaction">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'failed') {
    return (
      <Typography component="span" className="chip rejected-transaction">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'pending') {
    return (
      <Typography component="span" className="chip pending-transaction">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'refunded') {
    return (
      <Typography component="span" className="chip refunded-transaction">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'processing') {
    return (
      <Typography component="span" className="chip processing-transaction">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }
  return (
    <Typography component="span" className="chip finished">
      {firstLetterUppercase(status)}
    </Typography>
  );
};

export const getSubscriptionStatus = (status: string) => {
  if (status === 'Active') {
    return (
      <Typography component="span" className="chip active">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'Created') {
    return (
      <Typography component="span" className="chip created-subscription">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'Updated') {
    return (
      <Typography component="span" className="chip updated-subscription">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'Cancelled') {
    return (
      <Typography component="span" className="chip cancelled-subscription">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  return (
    <Typography component="span" className="chip finished-subscription">
      {firstLetterUppercase(status)}
    </Typography>
  );
};

export const getChipAdmin = (status: string) => {
  if (status === 'enabled') {
    return (
      <Typography component="span" className="chip active">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }
  if (status === 'disabled') {
    return (
      <Typography component="span" className="chip disabled">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  return (
    <Typography component="span" className="chip disabled">
      Disabled
    </Typography>
  );
};

export const getChipRegister = (status: string) => {
  if (status === 'approved') {
    return (
      <Typography component="span" className="chip approved">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }
  if (status === 'pending') {
    return (
      <Typography component="span" className="chip pending">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  if (status === 'declined') {
    return (
      <Typography component="span" className="chip declined">
        {firstLetterUppercase(status)}
      </Typography>
    );
  }

  return (
    <Typography component="span" className="chip pending">
      Pending
    </Typography>
  );
};
