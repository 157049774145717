import { HeadCell } from './types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'therapist',
    numeric: false,
    label: 'Therapist',
    align: 'left',
    sort: false
  },

  {
    id: 'type',
    numeric: false,
    label: 'Type',
    align: 'left',
    sort: false
  },
  {
    id: 'transactionDate',
    numeric: false,
    label: 'Transaction Date',
    align: 'left',
    sort: false
  },
  {
    id: 'amount',
    numeric: false,
    label: 'Amount',
    align: 'left',
    sort: false
  },

  {
    id: 'status',
    numeric: false,
    label: 'Status',
    align: 'left',
    sort: false
  }
];

export const appointmentHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Appointment with',
    align: 'left',
    sort: false
  },

  {
    id: 'booking',
    numeric: false,
    label: 'Booking #',
    align: 'left',
    sort: false
  },
  {
    id: 'date',
    numeric: false,
    label: 'Date',
    align: 'left',
    sort: false
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    align: 'left',
    sort: false
  }
];

export const subscriptionHistoryHeadCells: HeadCell[] = [
  {
    id: 'Subscription',
    numeric: false,
    label: 'Subscription',
    align: 'left',
    sort: false
  },

  {
    id: 'datePurchased',
    numeric: false,
    label: 'Date purchased',
    align: 'left',
    sort: false
  },
  {
    id: 'expiryDate',
    numeric: false,
    label: 'Expiry date',
    align: 'left',
    sort: false
  },
  {
    id: 'Status',
    numeric: false,
    label: 'Status',
    align: 'left',
    sort: false
  },
  {
    id: 'Invoice',
    numeric: false,
    label: 'Invoice',
    align: 'left',
    sort: false
  }
];

export const reviewHeadCells: HeadCell[] = [
  {
    id: 'clientImage',
    numeric: false,
    label: '',
    align: 'right',
    sort: false
  },
  {
    id: 'from',
    numeric: false,
    label: 'From',
    align: 'left',
    sort: false
  },

  {
    id: 'Date',
    numeric: false,
    label: 'Date',
    align: 'left',
    sort: false
  },
  {
    id: 'rating',
    numeric: false,
    label: 'Rating',
    align: 'left',
    sort: false
  },

  {
    id: 'review',
    numeric: false,
    label: 'Review',
    align: 'left',
    sort: false
  }
];

export const initialValues = {
  firstName: '',
  lastName: '',
  authProviderId: '',
  address: '',
  bio: ''
};

export enum auth {
  authProvider = 'email'
}

enum AppointmentStatus {
  BOOKED = 'booked',
  FINISHED = 'finished',
  CANCELLED = 'cancelled'
}

export const enumMap = {
  [AppointmentStatus.BOOKED]: 'booked',
  [AppointmentStatus.FINISHED]: 'finished',
  [AppointmentStatus.CANCELLED]: 'cancelled'
};
