// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import contactReducer from './slices/contact';
import calendarReducer from './slices/calendar';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import editorReducer from './slices/editor';
import authReducer from './slices/auth';
import tableReducer from './slices/table';
import pageReducer from './slices/page';
import formReducer from './slices/form';
import { taxonomyReducer, taxonomyRouteReducer } from 'hooks/reducers/useTaxonomyReducer';
import { pageDragIndexReducer } from 'hooks/reducers/usePageDragIndexReducer';
import settingReducer from './slices/settings';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  contact: contactReducer,
  calendar: calendarReducer,
  user: userReducer,
  menu: menuReducer,
  editor: editorReducer,
  auth: authReducer,
  table: tableReducer,
  page: pageReducer,
  form: formReducer,
  taxon: taxonomyReducer,
  taxonomyRoute: taxonomyRouteReducer,
  pageDragIndex: pageDragIndexReducer,
  settings: settingReducer
});

export default reducer;
