import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SearchIcon from '@mui/icons-material/Search';

import { useTheme } from '@mui/material';
import useDebouncedSearch from 'hooks/common/useDebounceSearch';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { Divider } from '@mui/material';
import { IconCheckboxAlt, IconCheckboxCheckedAlt } from 'constants/icons';
import useGQL from 'hooks/useGQL';
import { Location, TherapistPractice } from 'types/categories';
import useSnackbar from 'hooks/common/useSnackbar';
import useAlertDialog from 'hooks/common/useAlertDialog';
import { useDialog } from 'hooks/useDialogs';
import AddTherapistModal from './add-therapist';
import usePermissionRoles from 'hooks/common/usePermissionRoles';
import Spinner from 'components/spinner';

const CategoriesManagement = () => {
  const theme = useTheme();

  const { getConfirmation } = useAlertDialog();
  const { handleOpenSnackbar } = useSnackbar();
  const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();
  const [searchText, setSearchText] = useState<string>('');
  const [therapistPaginate, setTherapistPaginate] = useState<number>(100);
  const [sessionCostPaginate, setSessionCostPaginate] = useState<number>(100);
  const [locationPaginate, setLocationPaginate] = useState<number>(100);
  const [servicePaginate, setServicePaginate] = useState<number>(100);
  const [specialityPaginate, setSpecialityPaginate] = useState<number>(100);
  const [therapistOpen, setTherapistOpen] = useState<boolean>(false);
  const [serviceOpen, setServiceOpen] = useState<boolean>(false);
  const [sessionCostOpen, setSessionCostOpen] = useState<boolean>(false);
  const [locationOpen, setLocationOpen] = useState<boolean>(false);
  const [specialityOpen, setSpecialityOpen] = useState<boolean>(false);
  const [therapistPractice, setTherapistPractice] = useState<[TherapistPractice]>();
  const [service, setService] = useState<[TherapistPractice]>();
  const [speciality, setSpeciality] = useState<[TherapistPractice]>();

  const [sessionCost, setSessionCost] = useState<[TherapistPractice]>();

  const [locationData, setLocationData] = useState<[Location]>();
  const {
    UPDATE_THERAPIST_PRACTICE_STATUS,
    UPDATE_SERVICE_STATUS,
    UPDATE_SPECIALITY_STATUS,
    UPDATE_SESSION_COST_STATUS,
    UPDATE_COUNTRY_STATUS,
    UPDATE_STATE_STATUS,
    GET_ALL_THERAPIST_PRACTICE,
    GET_ALL_SERVICE,
    GET_ALL_SPECIALITY,
    GET_ALL_SESSION_COST,
    GET_ALL_LOCATION
  } = useGQL();

  const { IsEditCategory } = usePermissionRoles();
  const [handleChangeTherapistPracticeStatus] = UPDATE_THERAPIST_PRACTICE_STATUS();
  const [handleChangeServiceStatus] = UPDATE_SERVICE_STATUS();

  const [handleChangeSpecialityStatus] = UPDATE_SPECIALITY_STATUS();

  const [handleChangeSessionCostStatus] = UPDATE_SESSION_COST_STATUS();
  const [handleChangeCountryStatus] = UPDATE_COUNTRY_STATUS();
  const [handleChangeStateStatus] = UPDATE_STATE_STATUS();

  const {
    data,
    refetch,
    loading: therapistLoading
  } = GET_ALL_THERAPIST_PRACTICE({
    limit: therapistPaginate,
    skip: 0,
    order: 'desc',
    orderBy: '_id',
    searchText: searchText
  });

  const {
    data: serviceData,
    loading: serviceLoading,
    refetch: serviceRefetch
  } = GET_ALL_SERVICE({
    limit: servicePaginate,
    skip: 0,
    order: 'desc',
    orderBy: '_id',
    searchText: searchText
  });

  const {
    data: sessionCostData,
    loading: sessionCostLoading,
    refetch: sessionCostRefetch
  } = GET_ALL_SESSION_COST({
    limit: sessionCostPaginate,
    skip: 0,
    order: 'desc',
    orderBy: '_id',
    searchText: searchText
  });

  const {
    data: locationResponseData,
    loading: locationLoading,
    refetch: locationResponseRefetch
  } = GET_ALL_LOCATION({
    limit: locationPaginate,
    skip: 0,
    order: 'desc',
    orderBy: '_id',
    searchText: searchText
  });

  const {
    data: specialityData,
    loading: specialityLoading,
    refetch: specialityRefetch
  } = GET_ALL_SPECIALITY({
    limit: specialityPaginate,
    skip: 0,
    order: 'desc',
    orderBy: '_id',
    searchText: searchText
  });

  useEffect(() => {
    if (
      data &&
      data?.getAllTherapistPractices &&
      data?.getAllTherapistPractices?.therapistPractices
    ) {
      setTherapistPractice(data?.getAllTherapistPractices?.therapistPractices);
    }
  }, [data]);

  useEffect(() => {
    if (
      specialityData &&
      specialityData?.getAllSpecialities &&
      specialityData?.getAllSpecialities?.specialitys
    ) {
      setSpeciality(specialityData?.getAllSpecialities?.specialitys);
    }
  }, [specialityData]);

  useEffect(() => {
    if (serviceData && serviceData?.getAllServices && serviceData?.getAllServices?.services) {
      setService(serviceData?.getAllServices?.services);
    }
  }, [serviceData]);

  useEffect(() => {
    if (
      sessionCostData &&
      sessionCostData?.getAllSessionCosts &&
      sessionCostData?.getAllSessionCosts?.sessionCosts
    ) {
      setSessionCost(sessionCostData?.getAllSessionCosts?.sessionCosts);
    }
  }, [sessionCostData]);

  useEffect(() => {
    if (
      locationResponseData &&
      locationResponseData?.getAllLocations &&
      locationResponseData?.getAllLocations?.locations
    ) {
      setLocationData(locationResponseData?.getAllLocations?.locations);
    }
  }, [locationResponseData]);

  const [debouncedSearch] = useDebouncedSearch(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
      if (event?.target) {
        setTherapistPaginate(100);
        setServicePaginate(100);
        setSpecialityPaginate(100);
        setSessionCostPaginate(100);
        setLocationPaginate(100);
        setSearchText(event.target.value);
        handleRefetch();
      }
    }
  );
  const handleRefetch = () => {
    refetch({
      input: {
        limit: therapistPaginate,
        skip: 0,
        order: 'desc',
        orderBy: '_id',
        searchText: searchText
      }
    });

    serviceRefetch({
      input: {
        limit: servicePaginate,
        skip: 0,
        order: 'desc',
        orderBy: '_id',
        searchText: searchText
      }
    });

    locationResponseRefetch({
      input: {
        limit: locationPaginate,
        skip: 0,
        order: 'desc',
        orderBy: '_id',
        searchText: searchText
      }
    });

    sessionCostRefetch({
      input: {
        limit: sessionCostPaginate,
        skip: 0,
        order: 'desc',
        orderBy: '_id',
        searchText: searchText
      }
    });
    specialityRefetch({
      input: {
        limit: specialityPaginate,
        skip: 0,
        order: 'desc',
        orderBy: '_id',
        searchText: searchText
      }
    });
  };

  const cardActions = (
    <Stack
      direction="row"
      justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
      alignItems="center"
      gap={2}
      flexWrap="wrap"
    >
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          )
        }}
        onChange={debouncedSearch}
        placeholder="Search Categories"
        size="small"
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: '0',
            borderRadius: '8px',
            borderColor: theme.palette.blue[500]
          },

          '& .MuiOutlinedInput-input': {
            height: 'auto'
          }
        }}
      />
    </Stack>
  );

  const handleChangePracticeStatus = async (event: React.MouseEvent, id: string) => {
    // setAnchorEl(null);
    const isConfirm = await getConfirmation({
      title: `Change status`,
      message: `Are you sure you want to change status?`
    });
    if (isConfirm) {
      try {
        await handleChangeTherapistPracticeStatus({ variables: { id } });
        handleOpenSnackbar({
          message: `Status changed sussessfully`,
          alertType: 'success'
        });
        refetch();
      } catch (err: any) {
        // handleOpenSnackbar({ message: err.message, alertType: "error" });
      }
    }
  };

  const handleUpdateServiceStatus = async (event: React.MouseEvent, id: string) => {
    // setAnchorEl(null);
    const isConfirm = await getConfirmation({
      title: `Change status`,
      message: `Are you sure you want to change status?`
    });
    if (isConfirm) {
      try {
        await handleChangeServiceStatus({ variables: { id } });
        handleOpenSnackbar({
          message: `Status changed sussessfully`,
          alertType: 'success'
        });
        serviceRefetch();
      } catch (err: any) {
        // handleOpenSnackbar({ message: err.message, alertType: "error" });
      }
    }
  };

  const handleUpdateSessionCostStatus = async (event: React.MouseEvent, id: string) => {
    // setAnchorEl(null);
    const isConfirm = await getConfirmation({
      title: `Change status`,
      message: `Are you sure you want to change status?`
    });
    if (isConfirm) {
      try {
        await handleChangeSessionCostStatus({ variables: { id } });
        handleOpenSnackbar({
          message: `Status changed sussessfully`,
          alertType: 'success'
        });
        sessionCostRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };

  const handleUpdateCountryStatus = async (event: React.MouseEvent, id: string, status: string) => {
    // setAnchorEl(null);
    const isConfirm = await getConfirmation({
      title: `Change status`,
      message: `Are you sure you want to change status?`
    });
    if (isConfirm) {
      try {
        await handleChangeCountryStatus({ variables: { input: { id, status } } });
        handleOpenSnackbar({
          message: `Country Status changed sussessfully`,
          alertType: 'success'
        });
        locationResponseRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };

  const handleUpdateStateStatus = async (
    event: React.MouseEvent,
    id: string,
    status: string,
    stateTitle: string
  ) => {
    // setAnchorEl(null);
    const isConfirm = await getConfirmation({
      title: `Change status`,
      message: `Are you sure you want to change status?`
    });
    if (isConfirm) {
      try {
        await handleChangeStateStatus({ variables: { input: { id, status, stateTitle } } });
        handleOpenSnackbar({
          message: `State Status changed sussessfully`,
          alertType: 'success'
        });
        locationResponseRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };

  const handleUpdateSpecialityStatus = async (event: React.MouseEvent, id: string) => {
    // setAnchorEl(null);
    const isConfirm = await getConfirmation({
      title: `Change status`,
      message: `Are you sure you want to change status?`
    });
    if (isConfirm) {
      try {
        await handleChangeSpecialityStatus({ variables: { id } });
        handleOpenSnackbar({
          message: `Status changed sussessfully`,
          alertType: 'success'
        });
        specialityRefetch();
      } catch (err: any) {
        handleOpenSnackbar({ message: err.message, alertType: 'error' });
      }
    }
  };

  const OpenFilterModal = () => {
    setServiceOpen(false);
    setSpecialityOpen(false);
    setTherapistOpen(true);
    setSessionCostOpen(false);
    setLocationOpen(false);
    handleDialogOpen();
  };

  const OpenServiceFilterModal = () => {
    setServiceOpen(true);
    setSpecialityOpen(false);
    setTherapistOpen(false);
    setSessionCostOpen(false);
    setLocationOpen(false);
    handleDialogOpen();
  };

  const OpenSessionCostFilterModal = () => {
    setServiceOpen(false);
    setSpecialityOpen(false);
    setTherapistOpen(false);
    setSessionCostOpen(true);
    setLocationOpen(false);
    handleDialogOpen();
  };

  const OpenSpecialityFilterModal = () => {
    setServiceOpen(false);
    setSpecialityOpen(true);
    setTherapistOpen(false);
    setSessionCostOpen(false);
    setLocationOpen(false);
    handleDialogOpen();
  };

  const OpenLocationFilterModal = () => {
    setServiceOpen(false);
    setSpecialityOpen(false);
    setTherapistOpen(false);
    setSessionCostOpen(false);
    setLocationOpen(true);
    handleDialogOpen();
  };

  const closeDialog = () => {
    handleDialogClose();
  };

  return (
    <Stack rowGap={2}>
      <MainCard
        title="Categories Management"
        content={false}
        sx={{ position: 'relative' }}
        secondary={cardActions}
      >
        {locationLoading ||
        serviceLoading ||
        specialityLoading ||
        therapistLoading ||
        sessionCostLoading ? (
          <Spinner />
        ) : (
          <Box
            sx={{
              padding: '24px 20px',
              '.add-new': {
                minWidth: '154px'
              },

              '.checkbox-alt': {
                gap: 2,
                '@media screen and (min-width: 768px)': {
                  flexDirection: 'row'
                },

                '.MuiFormControlLabel-root': {
                  width: '100%',
                  margin: '0',
                  gap: 2,

                  '@media screen and (min-width: 768px)': {
                    width: 'calc(50% - 16px)'
                  },

                  '@media screen and (min-width: 1024px)': {
                    width: 'calc(33.33% - 16px)'
                  },

                  '.MuiCheckbox-root': {
                    padding: '0',
                    '&.Mui-checked': {
                      '& + .MuiFormControlLabel-label': {
                        background: '#E3F2FD',
                        borderColor: '#1565C0'
                      }
                    }
                  },

                  '.MuiFormControlLabel-label': {
                    order: '-1',
                    border: '0.2px solid rgba(21, 101, 192, 0.35)',
                    borderRadius: '8px',
                    padding: '10px 14px',
                    width: '218px',
                    color: '#1C1C1C',
                    fontSize: '0.875rem',
                    textAlign: 'center'
                  }
                }
              }
            }}
          >
            <>
              <Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="subtitle1" color="#2a2a2a" fontWeight="600">
                    Therapist
                  </Typography>
                  {IsEditCategory && (
                    <Button
                      variant="outlined"
                      endIcon={<AddIcon />}
                      className="add-new"
                      onClick={OpenFilterModal}
                    >
                      Add New
                    </Button>
                  )}

                  <AddTherapistModal
                    {...{ openDialog }}
                    refetch={refetch}
                    serviceRefetch={serviceRefetch}
                    specialityRefetch={specialityRefetch}
                    sessionCostRefetch={sessionCostRefetch}
                    locationResponseRefetch={locationResponseRefetch}
                    therapistOpen={therapistOpen}
                    setTherapistOpen={setTherapistOpen}
                    serviceOpen={serviceOpen}
                    setServiceOpen={setServiceOpen}
                    sessionCostOpen={sessionCostOpen}
                    setSessionCostOpen={setSessionCostOpen}
                    locationOpen={locationOpen}
                    setLocationOpen={setLocationOpen}
                    specialityOpen={specialityOpen}
                    setSpecialityOpen={setSpecialityOpen}
                    handleDialogClose={closeDialog}
                  />
                </Stack>
                <Box
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.40)',
                    borderRadius: '8px',
                    padding: 2
                  }}
                >
                  <FormGroup className="checkbox-alt">
                    {therapistPractice && therapistPractice.length > 0 ? (
                      therapistPractice.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.status === 'visible'}
                              icon={<IconCheckboxAlt />}
                              checkedIcon={<IconCheckboxCheckedAlt />}
                              disableRipple
                            />
                          }
                          label={item.title}
                          onClick={(event) => {
                            handleChangePracticeStatus(event, item._id);
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="h5">No data found</Typography>
                    )}
                  </FormGroup>
                </Box>
              </Stack>
              <Divider sx={{ margin: '24px 0', background: '#E3F2FD' }} />
              <Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="subtitle1" color="#2a2a2a" fontWeight="600">
                    Location
                  </Typography>
                  {IsEditCategory && (
                    <Button
                      variant="outlined"
                      endIcon={<AddIcon />}
                      className="add-new"
                      onClick={OpenLocationFilterModal}
                    >
                      Add New
                    </Button>
                  )}
                </Stack>

                {locationData && locationData.length > 0 ? (
                  locationData.map((countryItem, countryIndex) => (
                    <Box
                      key={countryIndex}
                      sx={{
                        border: '1px solid rgba(0, 0, 0, 0.40)',
                        borderRadius: '8px',
                        padding: 2,
                        marginTop: '5px'
                      }}
                    >
                      <FormGroup className="checkbox-alt">
                        <React.Fragment key={countryItem._id}>
                          <Stack direction="column" gap="22px" width="100%">
                            <Stack direction="column">
                              <Typography variant="subtitle1" color="#2a2a2a" fontWeight="600">
                                Country
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={countryItem?.country?.status === 'visible'}
                                    icon={<IconCheckboxAlt />}
                                    checkedIcon={<IconCheckboxCheckedAlt />}
                                    disableRipple
                                  />
                                }
                                label={countryItem.country.title}
                                onClick={(event) => {
                                  handleUpdateCountryStatus(
                                    event,
                                    countryItem._id,
                                    countryItem?.country?.status === 'visible'
                                      ? 'hidden'
                                      : 'visible'
                                  );
                                }}
                              />
                            </Stack>

                            <Stack direction="column">
                              <Typography variant="subtitle1" color="#2a2a2a" fontWeight="600">
                                States
                              </Typography>
                              <Stack direction="row" gap="22px" flexWrap="wrap">
                                {countryItem.state &&
                                  countryItem.state.length > 0 &&
                                  countryItem.state.map((stateItem, stateIndex) => (
                                    <FormControlLabel
                                      key={stateIndex}
                                      control={
                                        <Checkbox
                                          checked={stateItem.status === 'visible'}
                                          icon={<IconCheckboxAlt />}
                                          checkedIcon={<IconCheckboxCheckedAlt />}
                                          disableRipple
                                        />
                                      }
                                      label={stateItem.title}
                                      onClick={(event) => {
                                        handleUpdateStateStatus(
                                          event,
                                          countryItem._id,
                                          stateItem?.status === 'visible' ? 'hidden' : 'visible',
                                          stateItem.title
                                        );
                                      }}
                                    />
                                  ))}
                              </Stack>
                            </Stack>
                          </Stack>
                        </React.Fragment>
                      </FormGroup>
                    </Box>
                  ))
                ) : (
                  <Typography variant="h5">No data found</Typography>
                )}
              </Stack>
              <Divider sx={{ margin: '24px 0', background: '#E3F2FD' }} />
              <Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="subtitle1" color="#2a2a2a" fontWeight="600">
                    Service
                  </Typography>

                  {IsEditCategory && (
                    <Button
                      variant="outlined"
                      endIcon={<AddIcon />}
                      className="add-new"
                      onClick={OpenServiceFilterModal}
                    >
                      Add New
                    </Button>
                  )}
                </Stack>
                <Box
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.40)',
                    borderRadius: '8px',
                    padding: 2
                  }}
                >
                  <FormGroup className="checkbox-alt">
                    {service && service.length > 0 ? (
                      service.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.status === 'visible'}
                              icon={<IconCheckboxAlt />}
                              checkedIcon={<IconCheckboxCheckedAlt />}
                              disableRipple
                            />
                          }
                          label={item.title}
                          onClick={(event) => {
                            handleUpdateServiceStatus(event, item._id);
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="h5">No data found</Typography>
                    )}
                  </FormGroup>
                </Box>
              </Stack>
              <Divider sx={{ margin: '24px 0', background: '#E3F2FD' }} />
              <Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="subtitle1" color="#2a2a2a" fontWeight="600">
                    Specialty Areas
                  </Typography>

                  {IsEditCategory && (
                    <Button
                      variant="outlined"
                      endIcon={<AddIcon />}
                      className="add-new"
                      onClick={OpenSpecialityFilterModal}
                    >
                      Add New
                    </Button>
                  )}
                </Stack>
                <Box
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.40)',
                    borderRadius: '8px',
                    padding: 2
                  }}
                >
                  <FormGroup className="checkbox-alt">
                    {speciality && speciality.length > 0 ? (
                      speciality.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.status === 'visible'}
                              icon={<IconCheckboxAlt />}
                              checkedIcon={<IconCheckboxCheckedAlt />}
                              disableRipple
                            />
                          }
                          label={item.title}
                          onClick={(event) => {
                            handleUpdateSpecialityStatus(event, item._id);
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="h5">No data found</Typography>
                    )}
                  </FormGroup>
                </Box>
              </Stack>
              <Divider sx={{ margin: '24px 0', background: '#E3F2FD' }} />
              <Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="subtitle1" color="#2a2a2a" fontWeight="600">
                    Hourly session cost
                  </Typography>

                  {IsEditCategory && (
                    <Button
                      variant="outlined"
                      endIcon={<AddIcon />}
                      className="add-new"
                      onClick={OpenSessionCostFilterModal}
                    >
                      Add New
                    </Button>
                  )}
                </Stack>
                <Box
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.40)',
                    borderRadius: '8px',
                    padding: 2
                  }}
                >
                  <FormGroup className="checkbox-alt">
                    {sessionCost && sessionCost.length > 0 ? (
                      sessionCost.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.status === 'visible'}
                              icon={<IconCheckboxAlt />}
                              checkedIcon={<IconCheckboxCheckedAlt />}
                              disableRipple
                            />
                          }
                          label={item.title}
                          onClick={(event) => {
                            handleUpdateSessionCostStatus(event, item._id);
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="h5">No data found</Typography>
                    )}
                  </FormGroup>
                </Box>
              </Stack>
            </>
          </Box>
        )}
      </MainCard>
    </Stack>
  );
};

export default CategoriesManagement;
