import { gql } from '@apollo/client';

export const GET_ALL_APP_USERS = gql`
  query ($input: GetAppUsersDTO!) {
    getAllAppUsers(input: $input) {
      message
      users {
        _id
        address {
          displayAddress
          location {
            city
            coordinates {
              lat
              long
            }
            country
            displayAddress
            postalCode
            state
            street
            type
          }
        }
        currentSubscription {
          packageName
        }
        authProvider
        authProviderId
        bio
        clientProfile {
          dateOfBirth
          emergencyContacts {
            _id
            address
            firstName
            lastName
            email
            phone {
              dialCode
              number
            }
            postCode
            relationShip
            relationType
            state
            subUrb
          }
          medicalCondition {
            doctorName
            doctorPhoneNumber {
              dialCode
              number
            }
            existingMentalCondition
            prescriptionMedications
          }
          pronoun
          referrals
        }
        contacts {
          emailDetail {
            email
            isVerified
          }
          phone {
            dialCode
            number
          }
        }
        firstName
        lastLoggedInAt
        createdAt
        updatedAt
        lastName
        profileImage
        profileImageUrl
        status
        accountStatus
        registrationStatus
        therapistProfile {
          aboutYou {
            aboutMe
            experienceAndAchievement
            qualifications
            serviceAffiliation
            specialities
          }
          arcapNumber
          certificates
          consultationFee {
            followUpFee
            initialFee
          }
          identityDetail {
            cardNumber
            identitySource
            validUpto
          }
          medicareCardNumber
          medicareCardValidity
          practice
          title
        }
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_ALL_APPOINTMENTS = gql`
  query GetAllAppointment($body: GetTherapistAppointmentDTO!) {
    getAllAppointment(body: $body) {
      message
      pagination {
        hasNextPage
        total
      }
      appointments {
        _id
        appointmentStatus
        clientId
        consultationFee
        consultationMode
        startTime
        therapistId
        appointmentId
        client {
          _id
          accountStatus
          address {
            displayAddress
            location {
              city
              coordinates {
                lat
                long
              }
              country
              displayAddress
              postalCode
              state
              street
              type
            }
          }
          authProvider
          authProviderId
          bio
          clientProfile {
            dateOfBirth
            emergencyContacts {
              _id
              address
              firstName
              lastName
              phone {
                dialCode
                number
              }
              postCode
              relationShip
              relationType
              state
              subUrb
            }
            medicalCondition {
              doctorName
              doctorPhoneNumber {
                dialCode
                number
              }
              existingMentalCondition
              prescriptionMedications
            }
            pronoun
            referrals
          }
          contacts {
            emailDetail {
              email
              isVerified
            }
            phone {
              dialCode
              number
            }
          }
          createdAt
          firstName
          lastLoggedInAt
          lastName
          profileImage
          profileImageUrl
          registrationStatus
          status
          therapistProfile {
            aboutYou {
              aboutMe
              experienceAndAchievement
              qualifications
              serviceAffiliation
              specialities
            }
            arcapNumber
            certificates
            consultationFee {
              followUpFee
              initialFee
            }
            identityDetail {
              cardNumber
              identitySource
              validUpto
            }
            medicareCardNumber
            medicareCardValidity
            practice
            title
          }
          updatedAt
        }
        serviceDetail {
          medicalCardNumber
          familyName
          givenName
          referenceNumber
          serviceName
        }
        therapist {
          _id
          accountStatus
          authProvider
          authProviderId
          bio
          createdAt
          firstName
          lastLoggedInAt
          lastName
          profileImage
          profileImageUrl
          registrationStatus
          status
          updatedAt
        }
      }
    }
  }
`;

export const GET_THERAPIST_SUBSCRIPTION_HISTORY = gql`
  query Query($body: ListStripeSubscriptionInput!) {
    getTherapistSubscriptionHistory(body: $body) {
      data {
        _id
        amount
        createdAt
        subscriptionEndsAt
        subscriptionPlan
        subscriptionStatus
        updatedAt
      }
      pagination {
        hasNextPage
        total
      }
    }
  }
`;

export const GET_ALL_SUBSCRIPTION_PRODUCTS = gql`
  query FindSubscriptionProducts($body: BasePaginationParams!) {
    findSubscriptionProducts(body: $body) {
      data {
        _id
        name
      }
    }
  }
`;

export const GET_APP_USER = gql`
  query ($id: String!) {
    getAppUser(id: $id) {
      message
      user {
        _id
        address {
          displayAddress
          location {
            city
            coordinates {
              lat
              long
            }
            country
            displayAddress
            postalCode
            state
            street
            type
          }
        }
        authProvider
        authProviderId
        bio
        currentSubscription {
          packageName
        }
        clientProfile {
          dateOfBirth
          emergencyContacts {
            _id
            address
            email
            firstName
            lastName
            phone {
              dialCode
              number
            }
            postCode
            relationShip
            relationType
            state
            subUrb
          }
          medicalCondition {
            doctorName
            doctorPhoneNumber {
              dialCode
              number
            }
            existingMentalCondition
            prescriptionMedications
          }
          pronoun
          referrals
        }
        contacts {
          emailDetail {
            email
            isVerified
          }
          phone {
            dialCode
            number
          }
        }
        firstName
        lastLoggedInAt
        lastName
        profileImage
        profileImageUrl
        status
        accountStatus
        registrationStatus
        medicalService {
          _id
          birthDate
          cardType
          email
          familyName
          gender
          givenName
          medicalCardNumber
          nameOnCard
          programCode
          referenceNumber
          serviceId
          serviceName
        }
        card {
          brand
          exp_month
          exp_year
          funding
          last4
          userId
        }
        therapistProfile {
          aboutYou {
            aboutMe
            experienceAndAchievement
            qualifications
            serviceAffiliation
            specialities
          }
          arcapNumber
          certificates
          consultationFee {
            followUpFee
            initialFee
          }
          identityDetail {
            cardNumber
            identitySource
            validUpto
          }
          medicareCardNumber
          medicareCardValidity
          practice
          title
        }
      }
    }
  }
`;

export const DASHBOARD_DATA = gql`
  query DashboardData {
    dashboardData {
      message
      totalNoOfClientsThisWeek
      totalNoOfTherapistsThisMonth
      totalNoOfClients
      totalNoOfTherapists
    }
  }
`;

export const TRANSACTION_LIST = gql`
  query GetTransactionForAdmin($input: GetTransactions!) {
    getTransactionForAdmin(input: $input) {
      message
      data {
        _id
        amount
        appointmentId
        benefitAmount
        clientId
        description
        hicapClaimId
        hicapInvoiceId
        hicapStatus
        invoiceNumber
        itemCode
        itemName
        itemPublisher
        markAsSend
        quantity
        retryCount
        serviceDate
        status
        statusDescription
        therapistId
        totalAmount
        transactionFrom
        transactionType
        createdAt
        userId
        therapist {
          firstName
          lastName
          profileImage
          title
          email
          dialCode
          phone
        }
        client {
          firstName
          lastName
          profileImage
          email
          dialCode
          phone
        }
      }
      pagination {
        hasNextPage
        total
      }
    }
  }
`;

export const generateInvoiceByAdmin = gql`
  mutation GenerateInvoiceByAdmin($input: GetTransaction!) {
    generateInvoiceByAdmin(input: $input) {
      message
      url
    }
  }
`;
