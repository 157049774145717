import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Stack,
  Typography
} from '@mui/material';
import avatarProfile from 'assets/images/image-placeholder.png';
// project imports

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Breadcrumbs from 'components/Breadcrumbs';

import DetailCard from 'ui-component/cards/DetailCard';
import { getTransactionStatus } from 'ui-component/common';
import { firstLetterUppercase, formatDate } from 'utils/commonHelpers';
import CustomAvatarComponents from 'components/ImageDocument/AvatarComponents';
import { useNavigate } from 'react-router-dom';
import useGQL from 'hooks/useGQL';
import DocumentFileDownloader from 'components/ImageDocument/DocumentFileDownloader';
import useSnackbar from 'hooks/common/useSnackbar';

// tabs

// tabs option

// ==============================|| PROFILE 2 ||============================== //

const TransactionDetail = () => {
  const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
  const [downloadKey, setDownloadKey] = useState<number>(Date.now());

  const theme = useTheme();
  const { handleOpenSnackbar } = useSnackbar();

  const detail = JSON.parse(localStorage.getItem('transaction') || '{}');

  const { GenerateInvoiceByAdmin } = useGQL();
  const [generatePdf, { loading: isGeneratingInvoice }] = GenerateInvoiceByAdmin();

  const [donor, setDonor] = useState<any>(null);
  const [receiver, setReciever] = useState<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (detail.userId == detail.clientId) {
      setDonor({ ...detail.client, donorId: detail.clientId });
      setReciever({ ...detail.therapist, receiverId: detail.therapistId });
    } else {
      setDonor({ ...detail.therapist, donorId: detail.therapistId });
      setReciever({ ...detail.client, receiverId: detail.clientId });
    }
  }, [detail]);

  const onViewPdf = async () => {
    try {
      const generatedFileUrl = await generatePdf({
        variables: {
          input: {
            id: detail._id
          }
        }
      });

      if (generatedFileUrl.data?.generateInvoiceByAdmin?.url) {
        setSelectedDocument(generatedFileUrl.data?.generateInvoiceByAdmin?.url as string);
        setDownloadKey(Date.now());
      }
    } catch (error: any) {
      handleOpenSnackbar({ message: error.message, alertType: 'error' });
    }
  };
  const breadcrumb = (
    <Box>
      <MuiBreadcrumbs aria-label="breadcrumb" separator="›">
        <Link variant="h6" underline="none" color="#000" href="/transaction-management/list">
          Transaction management
        </Link>
        <Typography variant="h6" color="black">
          {`${localStorage.getItem('transactionType')}`}
        </Typography>
        <Typography variant="h6" color="black">
          {`${donor?.firstName} ${donor?.lastName}-${formatDate(detail?.createdAt)}`}
        </Typography>
      </MuiBreadcrumbs>
    </Box>
  );

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Stack rowGap={2}>
            <Breadcrumbs title={breadcrumb} />
            <MainCard
              title={`Transaction details-${donor?.firstName} ${donor?.lastName}-${formatDate(detail?.createdAt)}`}
              content={false}
            >
              <Box sx={{ padding: 3 }}>
                <Grid container columnSpacing={gridSpacing}>
                  <Grid item xs={12} md={8} lg={9} xl={10}>
                    <CardContent
                      sx={{
                        borderColor:
                          theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : theme.palette.grey[200],
                        height: '100%',
                        paddingTop: '0'
                      }}
                    >
                      <Stack direction="row" alignItems="center" gap="50px" mb={3}>
                        <Stack direction="column" gap="20px">
                          {' '}
                          <Typography
                            component="h1"
                            color="#212121"
                            fontSize="1rem"
                            fontWeight="500"
                          >
                            From
                          </Typography>
                          <Stack direction="row" gap={2}>
                            {donor?.profileImage ? (
                              <CustomAvatarComponents
                                s3Key={`profiles/${donor?.donorId}/${donor?.profileImage}`}
                                style={{
                                  background: '#fff',
                                  border: '1px solid #90CAF9',
                                  boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.16)',
                                  width: '82px',
                                  height: '82px'
                                }}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  background: '#fff',
                                  border: '1px solid #90CAF9',
                                  boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.16)',
                                  width: '82px',
                                  height: '82px'
                                }}
                                alt="User 1"
                                src={avatarProfile}
                              />
                            )}
                            <Stack direction="column" gap={1}>
                              <Stack direction="row" gap={2}>
                                <Typography variant="subtitle1" component="strong">
                                  Name
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {`${donor?.title ? donor.title : ''} ${donor?.firstName ? donor.firstName : ''} ${donor?.lastName ? donor.lastName : ''}`}
                                </Typography>
                              </Stack>
                              <Stack direction="row" gap={2}>
                                <Typography variant="subtitle1" component="strong">
                                  Email
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {`${donor?.email ? donor.email : 'N/A'}`}
                                </Typography>
                              </Stack>

                              <Stack direction="row" gap={2}>
                                <Typography variant="subtitle1" component="strong">
                                  Phone
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {`${donor?.dialCode ? donor.dialCode : ''} ${donor?.phone ? donor.phone : 'N/A'}`}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack direction="column" gap="20px">
                          {' '}
                          <Typography
                            component="h1"
                            color="#212121"
                            fontSize="1rem"
                            fontWeight="500"
                          >
                            To
                          </Typography>
                          <Stack direction="row" gap={2}>
                            {receiver?.profileImage ? (
                              <CustomAvatarComponents
                                s3Key={`profiles/${receiver?.receiverId}/${receiver?.profileImage}`}
                                style={{
                                  background: '#fff',
                                  border: '1px solid #90CAF9',
                                  boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.16)',
                                  width: '82px',
                                  height: '82px'
                                }}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  background: '#fff',
                                  border: '1px solid #90CAF9',
                                  boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.16)',
                                  width: '82px',
                                  height: '82px'
                                }}
                                alt="User 1"
                                src={avatarProfile}
                              />
                            )}
                            <Stack direction="column" gap={1}>
                              <Stack direction="row" gap={2}>
                                <Typography variant="subtitle1" component="strong">
                                  Name
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {`${receiver?.title ? receiver.title : ''} ${receiver?.firstName ? receiver.firstName : ''} ${receiver?.lastName ? receiver.lastName : ''}`}
                                </Typography>
                              </Stack>
                              <Stack direction="row" gap={2}>
                                <Typography variant="subtitle1" component="strong">
                                  Email
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {`${receiver?.email ? receiver.email : 'N/A'}`}
                                </Typography>
                              </Stack>
                              <Stack direction="row" gap={2}>
                                <Typography variant="subtitle1" component="strong">
                                  Phone
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {`${receiver?.dialCode ? receiver.dialCode : ''} ${receiver?.phone ? receiver.phone : 'N/A'}`}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Box sx={{ maxWidth: 720 }}>
                        <DetailCard title="Transaction info">
                          <List className="detail-list-desc">
                            <ListItem>
                              <Stack direction="row">
                                <Typography variant="subtitle1" component="strong">
                                  Transaction status
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {getTransactionStatus(detail.status)}
                                </Typography>
                              </Stack>
                            </ListItem>

                            <ListItem>
                              <Stack direction="row">
                                <Typography variant="subtitle1" component="strong">
                                  Amount
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {' '}
                                  {detail?.amount ? `AUD ${detail?.amount}` : 'N/A'}
                                </Typography>
                              </Stack>
                            </ListItem>

                            <ListItem>
                              <Stack direction="row">
                                <Typography variant="subtitle1" component="strong">
                                  Transaction Type
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {detail.transactionType
                                    ? firstLetterUppercase(detail.transactionType)
                                    : 'N/A'}
                                </Typography>
                              </Stack>
                            </ListItem>

                            <ListItem>
                              <Stack direction="row">
                                <Typography variant="subtitle1" component="strong">
                                  Invoice number
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>
                                <Box>
                                  <Typography variant="subtitle1" component="span">
                                    {detail?.invoiceNumber ? detail?.invoiceNumber : 'N/A'}
                                  </Typography>
                                  <Button
                                    variant="text"
                                    disabled={isGeneratingInvoice}
                                    onClick={onViewPdf}
                                    sx={{ color: '#1565C0', p: 0, textDecoration: 'underline' }}
                                  >
                                    View invoice
                                  </Button>
                                </Box>
                              </Stack>
                            </ListItem>

                            <ListItem>
                              <Stack direction="row">
                                <Typography variant="subtitle1" component="strong">
                                  Date of transaction
                                </Typography>
                                <Typography variant="subtitle1" component="span">
                                  :
                                </Typography>

                                <Typography variant="subtitle1" component="span">
                                  {detail?.createdAt ? formatDate(detail?.createdAt) : 'N/A'}
                                </Typography>
                              </Stack>
                            </ListItem>
                          </List>
                        </DetailCard>
                      </Box>
                    </CardContent>
                  </Grid>
                </Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    localStorage.removeItem('transactionId');
                    localStorage.removeItem('transactionType');
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              </Box>

              <Divider />
            </MainCard>
          </Stack>
        </Grid>
      </Grid>
      {selectedDocument && (
        <DocumentFileDownloader key={downloadKey} staticUrl={selectedDocument} />
      )}
    </>
  );
};

export default TransactionDetail;
