import { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Divider,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  FormGroup,
  Checkbox
} from '@mui/material';
import CloseModal from 'components/closeModal';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IconCalendarBlackLarge } from 'constants/icons';

import { Formik } from 'formik';
import { TransactionFilter } from '../constants/types';
import { firstLetterUppercase, formatDateForFilter } from 'utils/commonHelpers';
import { cloneDeep } from 'lodash';

type filterModalProps = {
  openDialog: boolean;
  setFilterArray?: any;
  filterArray?: any;
  setInitialVal?: any;
  initialVal?: any;
  handleDialogClose: () => void;
};

const FilterModal = ({
  openDialog,
  setFilterArray,
  filterArray,
  setInitialVal,
  initialVal,
  handleDialogClose
}: filterModalProps) => {
  const theme = useTheme();
  const formikForm = useRef<any>();

  const [transactionAny, setTransactionAny] = useState<boolean>(false);

  const [statusAny, setStatusAny] = useState<boolean>(false);

  const resetForm = () => {
    formikForm.current!.setValues({
      status: [],
      transactionType: [],
      dateCreated: {
        from: null,
        to: null
      }
    });
    _handleFilterSubmit({
      status: [],
      transactionType: [],
      dateCreated: {
        from: null,
        to: null
      }
    });

    setStatusAny(false);
    setTransactionAny(false);
  };

  const statusData = [
    { title: 'Completed', value: 'completed' },
    { title: 'Failed', value: 'failed' },
    { title: 'Refunded', value: 'refunded' },
    { title: 'Pending', value: 'pending' },
    { title: 'Processing', value: 'processing' }
  ];
  const transactionTypeData = [
    { title: 'Appointment', value: 'appointment' },
    { title: 'Subscription', value: 'subscription' }
  ];
  const _handleFilterSubmit = (values: TransactionFilter) => {
    let submitValues = cloneDeep(values);
    if (values?.status && values?.status.length === statusData.length) {
      submitValues.status = [];
    }

    if (values?.transactionType && values?.transactionType.length === transactionTypeData.length) {
      submitValues.transactionType = [];
    }

    setInitialVal(values);
    setFilterArray(submitValues);
    handleDialogClose();
  };

  const handleAllStatusCheckboxChange = (
    valueArray,
    value,
    setFieldValue,
    setAll,
    name,
    allData,
    all
  ) => {
    let array = valueArray || [];

    if (value === 'all') {
      if (all) {
        setAll(false);
        setFieldValue(name, []);
      } else {
        setAll(true);
        array = allData.map((item) => item.value);
        setFieldValue(name, array);
      }
      return;
    }

    if (array.includes(value)) {
      const filtered = array.filter((v) => v !== value);
      setFieldValue(name, filtered);
      if (filtered.length === allData.length) {
        setAll(true);
      } else {
        setAll(false);
      }
    } else {
      const updatedArray = [...array, value];
      setFieldValue(name, updatedArray);
      if (updatedArray.length === allData.length) {
        setAll(true);
      } else {
        setAll(false);
      }
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      className="filter-dialog"
      sx={{
        '.MuiDialog-paper': {
          padding: '0',
          maxWidth: '638px',
          borderRadius: '4px',

          '@media(min-width:768px)': {
            minWidth: '620px'
          }
        }
      }}
    >
      {openDialog && (
        <Box
          sx={{
            padding: '35px',
            '& .MuiDialogTitle-root': {
              padding: '0 24px 0 0'
            }
          }}
        >
          <Formik
            innerRef={formikForm}
            enableReinitialize
            initialValues={initialVal}
            onSubmit={(values, { setSubmitting }) => {
              _handleFilterSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle sx={{ position: 'relative' }}>
                  Filter
                  <CloseModal handleClose={handleDialogClose} />
                </DialogTitle>
                <Divider sx={{ borderColor: '#E9E1D5', margin: '24px 0' }} />
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    sx={{ p: '0px', textDecoration: 'underline' }}
                    variant="text"
                    color="primary"
                    onClick={() => resetForm()}
                  >
                    Clear All
                  </Button>
                </Stack>
                <DialogContent sx={{ padding: '10px' }}>
                  <Stack>
                    <Stack direction={'row'} gap={'30px'}>
                      <Stack direction="column" width="50%">
                        <Typography variant="h4" color={theme.palette.common.black} mb={3}>
                          Status
                        </Typography>
                        <FormGroup>
                          <>
                            <FormControlLabel
                              key="all"
                              value="all"
                              control={
                                <Checkbox
                                  checked={statusAny}
                                  onChange={() => {
                                    handleAllStatusCheckboxChange(
                                      values.status,
                                      'all',
                                      setFieldValue,
                                      setStatusAny,
                                      'status',
                                      statusData,
                                      statusAny
                                    );
                                  }}
                                />
                              }
                              label={firstLetterUppercase('all')}
                            />
                            {statusData.map((x) => (
                              <FormControlLabel
                                key={x.value}
                                value={x.value}
                                control={
                                  <Checkbox
                                    checked={values?.status && values?.status.includes(x.value)}
                                    onChange={() => {
                                      handleAllStatusCheckboxChange(
                                        values.status,
                                        x.value,
                                        setFieldValue,
                                        setStatusAny,
                                        'status',
                                        statusData,
                                        statusAny
                                      );
                                    }}
                                  />
                                }
                                label={firstLetterUppercase(x.title)}
                              />
                            ))}
                          </>
                        </FormGroup>
                      </Stack>
                      <Stack direction="column" width="50%">
                        <Typography variant="h4" color={theme.palette.common.black} mb={3}>
                          Transaction Type
                        </Typography>
                        <FormGroup>
                          <>
                            <FormControlLabel
                              key="all"
                              value="all"
                              control={
                                <Checkbox
                                  checked={transactionAny}
                                  onChange={() => {
                                    handleAllStatusCheckboxChange(
                                      values.transactionType,
                                      'all',
                                      setFieldValue,
                                      setTransactionAny,
                                      'transactionType',
                                      transactionTypeData,
                                      transactionAny
                                    );
                                  }}
                                />
                              }
                              label={firstLetterUppercase('all')}
                            />
                            {transactionTypeData.map((x) => (
                              <FormControlLabel
                                key={x.value}
                                value={x.value}
                                control={
                                  <Checkbox
                                    checked={
                                      values?.transactionType &&
                                      values?.transactionType.includes(x.value)
                                    }
                                    onChange={() => {
                                      handleAllStatusCheckboxChange(
                                        values.transactionType,
                                        x.value,
                                        setFieldValue,
                                        setTransactionAny,
                                        'transactionType',
                                        transactionTypeData,
                                        transactionAny
                                      );
                                    }}
                                  />
                                }
                                label={firstLetterUppercase(x.title)}
                              />
                            ))}
                          </>
                        </FormGroup>
                      </Stack>
                    </Stack>
                    <Divider sx={{ borderColor: '#E9E1D5', margin: '24px 0' }} />

                    <Typography variant="h4" color={theme.palette.common.black} mb={3}>
                      Transaction Date Range
                    </Typography>
                    <Stack direction="row" gap="22px">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          value={values?.dateCreated && values?.dateCreated.from}
                          onChange={(value: any) =>
                            setFieldValue(
                              'dateCreated.from',
                              formatDateForFilter(new Date(value)),
                              true
                            )
                          }
                          label="From"
                          onError={console.log}
                          inputFormat="dd/MM/yyyy"
                          mask="___/__/__ __"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconCalendarBlackLarge />
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        />

                        <MobileDatePicker
                          value={values?.dateCreated && values.dateCreated.to}
                          onChange={(value: any) =>
                            setFieldValue(
                              'dateCreated.to',
                              formatDateForFilter(new Date(value)),
                              true
                            )
                          }
                          label="To"
                          onError={console.log}
                          inputFormat="dd/MM/yyyy"
                          minDate={values?.dateCreated && values.dateCreated.from}
                          mask="___/__/__ __"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconCalendarBlackLarge />
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Divider sx={{ borderColor: '#E9E1D5', margin: '24px' }} />
                    <Box>
                      <Button type="submit" variant="contained" fullWidth color="primary">
                        Apply
                      </Button>
                    </Box>
                  </Stack>
                </DialogContent>
              </form>
            )}
          </Formik>
        </Box>
      )}
    </Dialog>
  );
};
export default FilterModal;
