// third-party
import { FormattedMessage } from 'react-intl';

// assets

// type
import { IconDash } from 'constants/icons';
import { NavItemType } from 'types';

const icons = {
  IconDashboard: IconDash
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard: NavItemType = {
  id: 'dashboard',
  title: <FormattedMessage id="Dashboard" />,
  icon: icons.IconDashboard,
  type: 'group',
  url: '/dashboard'
};

export default dashboard;
