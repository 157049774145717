import { FormattedMessage } from 'react-intl';
import { IconReviews } from 'constants/icons';
import { NavItemType } from 'types';
import { ACTION } from 'views/userManagement/admins/constants/variables';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reviewsManagement: NavItemType = {
  id: 'reviews-management',
  title: <FormattedMessage id="Reviews Management" />,
  icon: IconReviews,
  type: 'group',
  url: 'reviews-management/list',
  allowedRoles: ACTION.REVIEW_LIST
};

export default reviewsManagement;
