import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, CardContent, Divider, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';

// project imports

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets

import { TabsProps } from 'types';

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Breadcrumbs from 'components/Breadcrumbs';

import Therapist from './appUsers';
import Client from './clientUsers';
import { IconTransactionClient, IconTransactionTherapist } from 'constants/icons';

// tabs
function TabPanel({ children, value, index, ...other }: TabsProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

// tabs option

// ==============================|| PROFILE 2 ||============================== //

const TransactionManagement = () => {
  const theme = useTheme();
  const tabsOption = [
    {
      label: 'Therapist',
      icon: <IconTransactionTherapist />,
      title: 'Therapist',
      caption: 'Transaction history',
      visibility: false
    },
    {
      label: 'Client',
      icon: <IconTransactionClient />,
      title: 'Client',
      caption: 'Transaction history',
      visibility: false
    }
  ];
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const breadcrumb = (
    <Box>
      <MuiBreadcrumbs aria-label="breadcrumb" separator="›">
        <Link variant="h6" underline="none" color="#000" href="/transaction-management/list">
          Transaction management
        </Link>
        <Typography variant="h6" color="black">
          {value === 0 ? tabsOption[0].title : tabsOption[1].title}
        </Typography>
      </MuiBreadcrumbs>
    </Box>
  );

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Stack rowGap={2}>
          <Breadcrumbs title={breadcrumb} />
          <MainCard title="" content={false}>
            <Box sx={{ padding: 3 }}>
              <Grid container columnSpacing={gridSpacing}>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <CardContent sx={{ padding: '0' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      orientation="vertical"
                      variant="scrollable"
                      sx={{
                        '& .MuiTabs-flexContainer': {
                          borderBottom: 'none'
                        },
                        '& button': {
                          color:
                            theme.palette.mode === 'dark'
                              ? theme.palette.grey[600]
                              : theme.palette.grey[600],
                          minHeight: 'auto',
                          minWidth: '100%',
                          p: 2,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          justifyContent: 'flex-start',
                          borderRadius: '12px',

                          '.MuiTypography-subtitle1': {
                            color: '#757575'
                          },
                          '.MuiTypography-caption': {
                            color: theme.palette.grey[500]
                          }
                        },
                        '& button.Mui-selected': {
                          color: theme.palette.primary.main,
                          background:
                            theme.palette.mode === 'dark'
                              ? theme.palette.dark.main
                              : theme.palette.blue[50],

                          '.MuiTypography-subtitle1': {
                            color: '#000'
                          },

                          '.MuiTypography-caption': {
                            color: '#000'
                          },

                          '& > svg.MuiSvgIcon-root': {
                            path: {
                              fill: '#000'
                            }
                          }
                        },
                        '& button > svg': {
                          marginBottom: '0px !important',
                          marginRight: 1.25,
                          marginTop: 0,
                          height: 24,
                          width: 24,

                          '&.MuiSvgIcon-root': {
                            path: {
                              fill: '#9e9e9e'
                            }
                          }
                        },
                        '& button > div > span': {
                          display: 'block'
                        },
                        '& > div > span': {
                          display: 'none'
                        }
                      }}
                    >
                      {tabsOption.map((tab, index) => (
                        <Tab
                          key={index}
                          icon={tab.icon}
                          hidden={tab.visibility}
                          disabled={tab.visibility}
                          label={
                            <Grid container direction="column">
                              <Typography variant="subtitle1" color="inherit">
                                {tab.label}
                              </Typography>
                              <Typography
                                component="div"
                                variant="caption"
                                sx={{ textTransform: 'capitalize' }}
                              >
                                {tab.caption}
                              </Typography>
                            </Grid>
                          }
                          {...a11yProps(index)}
                        />
                      ))}
                    </Tabs>
                  </CardContent>
                </Grid>
                <Grid item xs={12} md={8} lg={9} xl={10}>
                  <CardContent
                    sx={{
                      borderLeft: '1px solid',
                      borderColor:
                        theme.palette.mode === 'dark'
                          ? theme.palette.background.default
                          : theme.palette.grey[200],
                      height: '100%',
                      paddingTop: '0'
                    }}
                  >
                    <TabPanel value={value} index={0}>
                      <Therapist />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Client />
                    </TabPanel>
                  </CardContent>
                </Grid>
              </Grid>
            </Box>

            <Divider />
          </MainCard>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TransactionManagement;
