import { gql } from '@apollo/client';

export const GET_AUTH_URL = gql`
  query {
    generateOtpAuthUrl {
      base32
      otpAuthUrl
    }
  }
`;

export const GET_IMAGE_URL_FROM_OBJECT_ID = gql`
  query GetDocOrImgFromObjKey($input: GetImgOrDocsDTO!) {
    getDocOrImgFromObjKey(input: $input) {
      message
      url
    }
  }
`;
