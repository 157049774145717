// third-party
import { FormattedMessage } from 'react-intl';

// type
import { IconTransaction } from 'constants/icons';
import { NavItemType } from 'types';
import { ACTION } from 'views/userManagement/admins/constants/variables';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const transactionManagement: NavItemType = {
  id: 'transaction-management',
  title: <FormattedMessage id="Transaction Management" />,
  icon: IconTransaction,
  type: 'group',
  url: 'transaction-management/list',
  allowedRoles: ACTION.TRANSACTION_LIST
};

export default transactionManagement;
