import { gql } from '@apollo/client';

export const UPDATE_THERAPIST_PRACTICE_STATUS = gql`
  mutation ($id: String!) {
    updateTherapistPracticeStatus(id: $id) {
      message
    }
  }
`;

export const UPDATE_SERVICE_STATUS = gql`
  mutation ($id: String!) {
    updateServiceStatus(id: $id) {
      message
    }
  }
`;

export const UPDATE_SESSION_COST_STATUS = gql`
  mutation ($id: String!) {
    updateSessionCostStatus(id: $id) {
      message
    }
  }
`;

export const UPDATE_COUNTRY_STATUS = gql`
  mutation ($input: UpdateCategoryStatusDTO!) {
    updateCountryStatus(input: $input) {
      message
    }
  }
`;

export const UPDATE_STATE_STATUS = gql`
  mutation ($input: UpdateStateStatusDTO!) {
    updateStateStatus(input: $input) {
      message
    }
  }
`;

export const UPDATE_SPECIALITY_STATUS = gql`
  mutation ($id: String!) {
    updateSpecialityStatus(id: $id) {
      message
    }
  }
`;

export const CREATE_THERAPIST_PRACTICE = gql`
  mutation Mutation($input: CreateTherapistPracticeDTO!) {
    createTherapistPractice(input: $input) {
      therapistPractice {
        _id
        createdAt
        title
        status
        updatedAt
      }
      pagination {
        hasNextPage
        total
      }
      message
    }
  }
`;

export const CREATE_SERVICE = gql`
  mutation Mutation($input: CreateServiceDTO!) {
    createService(input: $input) {
      service {
        _id
        createdAt
        title
        status
        updatedAt
      }
      pagination {
        hasNextPage
        total
      }
      message
    }
  }
`;

export const CREATE_SESSION_COST = gql`
  mutation Mutation($input: CreateSessionCostDTO!) {
    createSessionCost(input: $input) {
      sessionCost {
        _id
        createdAt
        title
        value
        status
        updatedAt
      }
      pagination {
        hasNextPage
        total
      }
      message
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation Mutation($input: CreateLocationDTO!) {
    createLocation(input: $input) {
      location {
        _id
        createdAt
        country {
          title
          status
        }
        state {
          title
          status
        }
        updatedAt
      }
      pagination {
        hasNextPage
        total
      }
      message
    }
  }
`;

export const CREATE_SPECIALITY = gql`
  mutation Mutation($input: CreateSpecialityDTO!) {
    createSpeciality(input: $input) {
      speciality {
        _id
        createdAt
        title
        status
        updatedAt
      }
      pagination {
        hasNextPage
        total
      }
      message
    }
  }
`;
