import { gql } from '@apollo/client';

export const UPDATE_ADMIN = gql`
  mutation ($id: String!, $input: UpdateAdminDTO!) {
    updateAdmin(id: $id, input: $input) {
      message
      admin {
        _id
        firstName
        lastName
        email
        status
        role
        phone
      }
    }
  }
`;

export const UPDATE_ADMIN_STATUS = gql`
  mutation ($id: String!) {
    updateAdminStatus(id: $id) {
      message
      admin {
        _id
        firstName
        lastName
        email
        status
        role
        phone
      }
    }
  }
`;

export const UPDATE_ADMIN_PERMISSION = gql`
  mutation ($id: String!, $permissionArray: [String!]!) {
    updateAdminPermission(id: $id, permissionArray: $permissionArray) {
      message
      admin {
        _id
        firstName
        lastName
        email
        status
        role
        phone
        permissions
      }
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation ($id: String!) {
    removeAdmin(id: $id) {
      message
      admin {
        _id
      }
    }
  }
`;

export const SEND_ADMIN_RESET_PASSWORD_MAIL = gql`
  mutation ($email: String!, $name: String!, $userId: String!) {
    sendAdminPasswordResetMail(email: $email, name: $name, userId: $userId)
  }
`;
