import moment from 'moment';

export const truncateString = (str, num) => {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
};

export const firstLetterUppercase = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const formatDateString = (dateStr: string) => {
  const date = moment(dateStr, 'DD-MM-YYYY').toISOString();
  return moment(date).format('DD/MM/YYYY');
};

export const formatDateWithDash = (date: Date): string => {
  return moment(date).format('DD-MM-YYYY');
};

export const capitalizeFirstLetterOfEveryWord = (str: string) => {
  if (!str) return '';
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const capitalizeFirstWordOfEachSentence = (str: string) => {
  if (!str) return '';

  // Split the text into sentences using regular expression
  const sentences = str.split(/([.!?]\s*)/);

  // Capitalize the first word of each sentence
  const capitalizedSentences = sentences.map((sentence) => {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  });

  // Join the sentences back together
  return capitalizedSentences.join('');
};

export const formatDateWithDay = (date) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const dateString = date.toLocaleDateString('en-GB', options);

  // Extract day, month, and year from dateString
  const [day, month, year] = dateString.split(' ');

  // Add ordinal suffix to day
  const dayInt = parseInt(day, 10);
  const dayWithSuffix =
    dayInt +
    (dayInt % 10 === 1 && dayInt !== 11
      ? 'st'
      : dayInt % 10 === 2 && dayInt !== 12
        ? 'nd'
        : dayInt % 10 === 3 && dayInt !== 13
          ? 'rd'
          : 'th');

  // Return formatted string with comma after month
  return `${dayWithSuffix} ${month}, ${year}`;
};

export const formatDateForFilter = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const getFileType = (path: string) => {
  const parts = path.split('.');
  return parts[parts.length - 1];
};

export const getFileName = (path: string) => {
  const parts = path.split('/');
  return parts[parts.length - 1];
};

export const generatePassword = (length = 8) => {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+[]{}|;:',.<>?";
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    password += chars[randomIndex];
  }
  return password;
};
