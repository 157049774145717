import { gql } from '@apollo/client';

export const UPDATE_REVIEW_STATUS = gql`
  mutation ($id: String!) {
    updateReviewStatus(id: $id) {
      message
      review {
        _id
        appointmentId
        therapistId
        clientId
        client {
          firstName
          lastName
          profileImage
          pronoun
        }
        createdAt
        updatedAt
        rating
        reviewText
        status
      }
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation ($id: String!) {
    deleteReview(id: $id) {
      message
    }
  }
`;
