export const initialValues = {
  title: ''
};

export const serviceInitialValues = {
  title: '',
  programCode: ''
};

export const locationInitialValues = {
  country: '',
  state: ''
};
