import { gql } from '@apollo/client';

export const LIST_PAGES = gql`
  query ($input: GetPagesDTO!) {
    listPages(input: $input) {
      message
      pages {
        _id
        title
        slug
        status
        content
        createdBy
        updatedAt
        createdAt
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const LIST_PAGE = gql`
  query ($id: String!) {
    listPage(id: $id) {
      message
      page {
        _id
        title
        slug
        content
        status
        banner {
          image
          altText
          slug
          content
          button {
            heading
            destinationUrl
          }
          uuid
          index
        }
        homePage {
          image
          altText
          slug
          content
          button {
            heading
            destinationUrl
          }
          uuid
          index
        }
        imageColumn {
          heading
          sections {
            id
            image
            alignment
            iconHeading
            subText
          }
          uuid
          index
        }
        howItWorks {
          heading
          sections {
            id
            image
            title
            description
          }
          uuid
          index
        }
        featuredProducts {
          heading
          sections {
            id
            image
            name
            productType
          }
          uuid
          index
        }
        seoTags {
          title
          tags
          description
        }
        faq {
          showFaqs
          selectedFaqs
          uuid
          index
          disabled
        }
        testimonials {
          heading
          showTestimonials
          uuid
          index
          disabled
          selectedTestimonials
        }
        createdAt
        updatedAt
      }
    }
  }
`;
