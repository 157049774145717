// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <svg
      width="232"
      height="46"
      viewBox="0 0 232 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="logo"
    >
      <g clip-path="url(#clip0_6403_9344)">
        <path
          d="M58.8782 7.14564H53.3218V3.88965H68.4606V7.14564H62.8975V21.1339H58.8782V7.14564Z"
          fill="#0B2D48"
        />
        <path
          d="M81.1838 17.4354H73.1187L71.5815 21.1273H67.4624L75.2082 3.88965H79.1742L86.9399 21.1273H82.721L81.1838 17.4354ZM79.9195 14.4039L77.1646 7.80609L74.4096 14.4039H79.9195Z"
          fill="#0B2D48"
        />
        <path
          d="M88.7568 3.88965H92.7761V17.8779H101.487V21.1273H88.7568V3.88965Z"
          fill="#0B2D48"
        />
        <path
          d="M110.071 14.3577L107.735 16.7683V21.1273H103.743V3.88965H107.735V11.9471L115.428 3.88965H119.893L112.7 11.5772L120.319 21.1273H115.627L110.071 14.3577Z"
          fill="#0B2D48"
        />
        <path
          d="M148.999 3.88965V21.1273H145.698L137.041 10.6658V21.1273H133.068V3.88965H136.396L145.033 14.3577V3.88965H148.999Z"
          fill="#0B2D48"
        />
        <path
          d="M156.658 20.269C155.214 19.4963 154.076 18.433 153.258 17.0791C152.439 15.7252 152.027 14.1995 152.027 12.5088C152.027 10.818 152.439 9.29241 153.258 7.9385C154.076 6.58459 155.208 5.52127 156.658 4.74855C158.109 3.97583 159.733 3.59277 161.536 3.59277C163.339 3.59277 164.963 3.98244 166.4 4.74855C167.838 5.52127 168.969 6.58459 169.801 7.9385C170.626 9.29241 171.039 10.818 171.039 12.5088C171.039 14.1995 170.626 15.7252 169.801 17.0791C168.976 18.433 167.838 19.4963 166.4 20.269C164.963 21.0417 163.339 21.4248 161.536 21.4248C159.733 21.4248 158.109 21.0417 156.658 20.269ZM164.311 17.3234C165.136 16.8545 165.788 16.2007 166.261 15.3685C166.733 14.5297 166.966 13.5787 166.966 12.5088C166.966 11.4389 166.733 10.4878 166.261 9.64906C165.788 8.81029 165.143 8.15645 164.311 7.69414C163.486 7.22522 162.554 6.99407 161.529 6.99407C160.505 6.99407 159.58 7.23183 158.748 7.69414C157.923 8.16305 157.271 8.81689 156.798 9.64906C156.326 10.4878 156.093 11.4389 156.093 12.5088C156.093 13.5787 156.326 14.5297 156.798 15.3685C157.271 16.2073 157.916 16.8611 158.748 17.3234C159.573 17.7923 160.505 18.0235 161.529 18.0235C162.554 18.0235 163.479 17.7923 164.311 17.3234Z"
          fill="#0B2D48"
        />
        <path
          d="M199.746 3.88965L194.063 21.1273H189.744L185.925 9.4572L181.979 21.1273H177.687L171.977 3.88965H176.149L180.069 16.0088L184.161 3.88965H187.881L191.854 16.1079L195.9 3.88965H199.746Z"
          fill="#0B2D48"
        />
        <path
          d="M210.649 26.5996H214.234V43.5526H210.649V26.5996ZM202.488 43.5526H198.903V26.5996H202.488V43.5526ZM210.916 36.4808H202.221V33.4292H210.916V36.4808Z"
          fill="#0B2D48"
        />
        <path
          d="M187.561 43.5526V29.5785H182.063V26.5996H196.667V29.5785H191.17V43.5526H187.561Z"
          fill="#0B2D48"
        />
        <path
          d="M171.113 43.5526V26.5996H174.698V40.5737H183.344V43.5526H171.113Z"
          fill="#0B2D48"
        />
        <path
          d="M150.412 43.5526L158.016 26.5996H161.552L169.157 43.5526H165.403L159.058 28.4644H160.462L154.117 43.5526H150.412ZM154.093 39.7987L155.037 37.0378H163.95L164.919 39.7987H154.093Z"
          fill="#0B2D48"
        />
        <path
          d="M139.782 33.5261H148.065V36.3597H139.782V33.5261ZM140.048 40.6222H149.421V43.5526H136.488V26.5996H149.106V29.5301H140.048V40.6222Z"
          fill="#0B2D48"
        />
        <path
          d="M128.651 26.5996H132.236V43.5526H128.651V26.5996ZM120.49 43.5526H116.905V26.5996H120.49V43.5526ZM128.918 36.4808H120.223V33.4292H128.918V36.4808Z"
          fill="#0B2D48"
        />
        <path
          d="M103.951 33.5261H112.234V36.3597H103.951V33.5261ZM104.217 40.6222H113.59V43.5526H100.657V26.5996H113.275V29.5301H104.217V40.6222Z"
          fill="#0B2D48"
        />
        <path
          d="M86.0884 43.5526V26.5996H89.6727V40.5737H98.3188V43.5526H86.0884Z"
          fill="#0B2D48"
        />
        <path
          d="M73.1336 33.5261H81.4163V36.3597H73.1336V33.5261ZM73.4 40.6222H82.7726V43.5526H69.8398V26.5996H82.4577V29.5301H73.4V40.6222Z"
          fill="#0B2D48"
        />
        <path
          d="M58.4976 43.5526V29.5785H53V26.5996H67.6038V29.5785H62.1062V43.5526H58.4976Z"
          fill="#0B2D48"
        />
        <path
          d="M33.0329 22.6401C32.5338 25.0044 31.5091 27.316 29.9719 29.5153C28.814 31.173 27.9356 32.7713 27.3301 34.3167C27.0306 31.5495 26.0458 28.8681 24.4221 26.5631C23.9563 25.9027 23.5038 25.2092 23.0779 24.4695C23.2443 24.4959 23.4173 24.5157 23.5836 24.5289C23.8165 24.5421 24.0494 24.5553 24.2757 24.5553C26.2388 24.5553 27.7892 23.8751 28.641 22.6268C29.2199 21.7881 29.7323 20.3417 28.8939 18.2151C28.2218 16.4979 27.0706 15.0383 25.5733 13.9816C24.0428 12.9051 22.2461 12.3305 20.3962 12.3305H20.2564C17.4948 12.3701 14.7998 13.6844 13.063 15.8441C11.3328 17.9905 10.694 20.6851 11.2663 23.426C11.6589 24.7733 12.2578 25.8564 13.063 26.6886C13.1362 26.7678 13.216 26.8405 13.2892 26.9131C14.1743 27.7321 15.2856 28.267 16.6298 28.5246C15.6516 26.649 14.7865 24.8261 15.4785 23.6439C15.7114 22.9108 17.1355 21.7022 18.2534 21.689C18.5063 21.689 18.7326 21.8079 18.8923 21.9994C18.9122 22.0258 18.9322 22.0523 18.9522 22.0787C19.5244 22.7655 20.0501 23.492 20.4694 24.278C21.1481 25.5526 21.8668 26.7216 22.6387 27.8113C24.9212 31.0475 25.753 35.1423 24.9544 39.0191C21.9932 39.5276 18.9189 38.9861 16.3835 37.3878C15.6249 36.9123 14.8796 36.3575 14.1676 35.7433C11.6389 33.5506 9.57606 30.638 8.37827 27.534C7.34683 24.8724 6.97418 22.1447 7.2936 19.6416C7.64628 16.9074 8.80415 14.4505 10.7339 12.3437L10.7472 12.3239L10.7606 12.3107C13.3425 9.38492 16.9625 7.77344 20.9551 7.77344C24.9478 7.77344 28.661 9.43115 30.9434 12.2182C33.1793 14.9459 33.9047 18.5519 33.0396 22.6401"
          fill="#00A0E1"
        />
        <path
          d="M43.686 31.8666L42.3351 32.9101C41.6763 33.5045 41.6231 34.2904 41.8693 35.1688C41.9026 35.2877 41.9492 35.4 41.9891 35.5189C42.3151 36.4237 42.1355 37.2096 41.4567 37.8766C40.9909 38.372 40.8312 38.9928 40.8578 39.6863C40.8978 40.5316 40.8246 41.377 40.5451 42.1828C38.4755 48.1466 28.9331 46.4889 28.5405 40.5977C28.1412 37.4672 29.3723 34.1716 31.7479 30.7571C33.3649 28.4389 34.5627 25.8499 35.1483 23.0959C37.4574 12.1523 29.3856 5.62052 20.9278 5.62052C16.6557 5.62052 12.2837 7.28484 9.09624 10.8909C-1.33125 22.2373 9.90143 40.5184 22.0923 41.7006C20.4487 45.8152 16.2431 46.8917 12.3769 45.2934C10.6667 44.5802 9.22267 43.3649 8.138 41.8723C1.50354 32.6789 -0.679115 24.747 0.172652 17.6736C0.565263 14.4705 1.63663 11.3664 3.49321 8.7114C13.1887 -5.17116 38.1495 -2.21897 41.2105 15.8112C41.4168 17.0264 41.3769 18.268 41.2105 19.4899C41.0042 20.9098 41.2305 22.2835 41.8626 23.5912L44.3913 28.8418C44.8971 29.8919 44.5976 31.1533 43.6726 31.8732"
          fill="#00CC9B"
        />
        <path
          d="M24.2687 22.3958C24.0957 22.3958 23.9227 22.3892 23.7363 22.3759C22.9245 22.3165 22.1725 22.0127 21.5271 21.5438C21.1744 21.2862 20.855 20.9824 20.5755 20.6324C19.9566 19.853 19.1448 19.536 18.293 19.5228H18.2664C16.2102 19.5228 13.9144 21.339 13.3954 22.9836C12.4172 18.3274 16.1037 14.5563 20.2827 14.4902H20.3892C23.2639 14.4902 25.8125 16.3263 26.8639 18.9945C27.7623 21.2796 26.478 22.3892 24.2753 22.3892"
          fill="#0B2D48"
        />
      </g>
      <defs>
        <clipPath id="clip0_6403_9344">
          <rect width="232" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
