// third-party
import { IconCategories } from 'constants/icons';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const categoriesManagement: NavItemType = {
  id: 'categories-management',
  title: <FormattedMessage id="Categories Management" />,
  icon: IconCategories,
  type: 'group',
  url: 'categories-management/list'
};

export default categoriesManagement;
