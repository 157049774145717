import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';
import { IconPage } from 'constants/icons';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const PageManagement: NavItemType = {
  id: 'page-management',
  title: <FormattedMessage id="Page Management" />,
  icon: IconPage,
  type: 'group',
  url: 'page-management/list'
};

export default PageManagement;
