import { useNavigate } from 'react-router-dom';

// project imports
import { useEffect } from 'react';
import { GuardProps } from 'types';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const isValidToken: string = localStorage.getItem('refreshToken') || '';

  const isRememberMe = localStorage.getItem('rememberMe');
  const isSessionMe = sessionStorage.getItem('sessionRemember');

  useEffect(() => {
    if ((isRememberMe == 'false' && !isSessionMe) || !isValidToken) {
      navigate('/login', { replace: true });
    }
  }, []);

  return children;
};

export default AuthGuard;
