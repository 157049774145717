import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type BreadcrumbsProps = {
  title: React.ReactNode | string;
};

function Breadcrumbs({ title }: BreadcrumbsProps) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        padding: '8px 16px',
        borderRadius: '12px',

        '.MuiBreadcrumbs-separator': {
          fontSize: '24px',
          marginTop: '-2px',
          color: theme.palette.grey[500]
        },

        '.MuiBreadcrumbs-ol li a': {
          color: theme.palette.grey[800],
          fontSize: '0.75rem',
          transition: '0.3s ease',

          '&:hover': {
            color: theme.palette.grey[500]
          }
        },

        '.MuiBreadcrumbs-ol li > *': {
          color: theme.palette.grey[800],
          fontSize: '0.75rem'
        }
      }}
    >
      {title}
    </Card>
  );
}

export default Breadcrumbs;
