import { gql } from '@apollo/client';

export const CREATE_APP_USER = gql`
  mutation ($input: CreateAppUserDTO!) {
    createAppUser(input: $input) {
      message
      user {
        _id
        authProvider
        authProviderId
        firstName
        lastName
        address {
          displayAddress
          location {
            type
            coordinates
          }
        }
        bio
        status
        lastLoggedInAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_APP_USER = gql`
  mutation ($id: String!, $input: UpdateAppUserDTO!) {
    updateAppUser(id: $id, input: $input) {
      message
      user {
        _id
        authProvider
        authProviderId
        firstName
        lastName
        address {
          displayAddress
          location {
            type
            coordinates
          }
        }
        bio
        status
        lastLoggedInAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_APP_USER = gql`
  mutation ($id: String!) {
    deleteAppUser(id: $id) {
      message
    }
  }
`;

export const APP_USER_CHANGE_PASSWORD = gql`
  mutation ($input: AppUserChangePasswordDTO!) {
    appUserchangePassword(input: $input) {
      message
    }
  }
`;

export const APP_USER_RESET_PASSWORD = gql`
  mutation ($input: AppUserResetPasswordDTO!) {
    appUserResetPassword(input: $input)
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation ($id: String!) {
    updateUserStatus(id: $id) {
      message
      users {
        _id
        address {
          displayAddress
          location {
            city
            coordinates {
              lat
              long
            }
            country
            displayAddress
            postalCode
            state
            street
            type
          }
        }
        authProvider
        authProviderId
        bio
        clientProfile {
          dateOfBirth
          emergencyContacts {
            _id
            address
            firstName
            lastName
            phone {
              dialCode
              number
            }
            postCode
            relationShip
            relationType
            state
            subUrb
          }
          medicalCondition {
            doctorName
            doctorPhoneNumber {
              dialCode
              number
            }
            existingMentalCondition
            prescriptionMedications
          }
          pronoun
          referrals
        }
        contacts {
          emailDetail {
            email
            isVerified
          }
          phone {
            dialCode
            number
          }
        }
        firstName
        lastLoggedInAt
        lastName
        profileImage
        profileImageUrl
        status
        accountStatus
        therapistProfile {
          aboutYou {
            aboutMe
            experienceAndAchievement
            qualifications
            serviceAffiliation
            specialities
          }
          arcapNumber
          certificates
          consultationFee {
            followUpFee
            initialFee
          }
          identityDetail {
            cardNumber
            identitySource
            validUpto
          }
          medicareCardNumber
          medicareCardValidity
          practice
          title
        }
      }
    }
  }
`;

export const UPDATE_REGISTRATION_STATUS = gql`
  mutation ($input: UpdateRegistrationStatusDTO!) {
    updateRegistrationStatus(input: $input) {
      message
      users {
        _id
        address {
          displayAddress
          location {
            city
            coordinates {
              lat
              long
            }
            country
            displayAddress
            postalCode
            state
            street
            type
          }
        }
        authProvider
        authProviderId
        bio
        clientProfile {
          dateOfBirth
          emergencyContacts {
            _id
            address
            firstName
            lastName
            phone {
              dialCode
              number
            }
            postCode
            relationShip
            relationType
            state
            subUrb
          }
          medicalCondition {
            doctorName
            doctorPhoneNumber {
              dialCode
              number
            }
            existingMentalCondition
            prescriptionMedications
          }
          pronoun
          referrals
        }
        contacts {
          emailDetail {
            email
            isVerified
          }
          phone {
            dialCode
            number
          }
        }
        firstName
        lastLoggedInAt
        lastName
        profileImage
        profileImageUrl
        status
        accountStatus
        registrationStatus
        therapistProfile {
          aboutYou {
            aboutMe
            experienceAndAchievement
            qualifications
            serviceAffiliation
            specialities
          }
          arcapNumber
          certificates
          consultationFee {
            followUpFee
            initialFee
          }
          identityDetail {
            cardNumber
            identitySource
            validUpto
          }
          medicareCardNumber
          medicareCardValidity
          practice
          title
        }
      }
    }
  }
`;

export const SEND_RESET_PASSWORD_MAIL = gql`
  mutation ($email: String!, $name: String!, $userId: String!) {
    sendPasswordResetMail(email: $email, name: $name, userId: $userId)
  }
`;
