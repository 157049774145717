import { Typography } from '@mui/material';
import spinner from '../../assets/images/error.png';
import styled from 'styled-components';

const FailureLoad = () => (
  <main>
    <Typography variant="h2" component="h1" align="center">
      Server api call failed...
    </Typography>
    <SpinnerWrapper>
      <Img src={spinner} alt="loading" />
    </SpinnerWrapper>
  </main>
);

export default FailureLoad;

const Img = styled.img`
  height: 150%;
`;
const SpinnerWrapper = styled.div`
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`;
