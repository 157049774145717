// project imports
import { Theme } from '@mui/material/styles';

import { IconCheckbox, IconCheckboxChecked } from 'constants/icons';

export default function componentStyleOverrides(
  theme: Theme,
  borderRadius: number,
  outlinedFilled: boolean
) {
  const mode = theme.palette.mode;
  const bgColor = mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[50];
  const menuSelectedBack =
    mode === 'dark' ? theme.palette.secondary.main + 15 : theme.palette.secondary.light;
  const menuSelected =
    mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '1.42857',
          fontWeight: 500,
          borderRadius: '8px',
          padding: '11px 10px',
          '&.approve': {
            color: '#00C853',
            borderColor: '#00C853',
            minWidth: '104px',

            '&:hover': {
              borderColor: '#00C853',
              backgroundColor: '#00C853',
              color: '#fff',
              svg: {
                path: {
                  fill: '#fff'
                }
              }
            }
          },
          '&.decline': {
            color: '#D84315',
            borderColor: '#D84315',
            minWidth: '104px',
            '&:hover': {
              borderColor: '#D84315',
              backgroundColor: '#D84315',
              color: '#fff',
              svg: {
                path: {
                  fill: '#fff'
                }
              }
            }
          }
        },
        containedPrimary: {
          backgroundColor: theme.palette.blue[800],
          transition: '0.3s ease',
          '&:hover': {
            backgroundColor: theme.palette.blue[600]
          }
        },
        outlinedPrimary: {
          color: theme.palette.grey[900],
          borderColor: theme.palette.blue[500],
          '&:hover': {
            color: theme.palette.background.paper,
            borderColor: theme.palette.blue[500],
            backgroundColor: theme.palette.blue[500]
          }
        }
      }
    },
    // MuiIconButton: {
    //     root: {
    //   styleOverrides: {
    //       // border: `1px solid ${theme.palette.blue[500]}`,
    //       borderRadius: "4px",
    //       padding: "7px",
    //       minWidth: "40px",
    //       minHeight: "40px",

    //       ":hover": {
    //         // borderColor: `1px solid ${theme.palette.blue[500]}`,
    //         backgroundColor: theme.palette.blue[500],
    //         color: theme.palette.background.paper,

    //         "svg path": {
    //           fill: theme.palette.background.paper,
    //         },
    //       },

    //       ".MuiTouchRipple-root": {
    //         display: "none",
    //       },
    //     },
    //   },
    // },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        },
        rounded: {
          borderRadius: `${borderRadius}px`
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: '14px 20px',
          borderBottom: `2px solid ${theme.palette.blue[50]}`
        },
        title: {
          fontSize: '1.25rem',
          lineHeight: '1.4',
          fontWeight: '700',
          color: theme.palette.grey[800]
        },
        action: {
          margin: '0',
          '.MuiFormControl-root': {
            '.MuiOutlinedInput-root': {
              background: 'transparent',
              paddingLeft: '7px',
              '&.MuiFoused': {
                '.MuiOutlinedInput-notchedOutline': {
                  // border: `1px solid ${theme.palette.primary.mainFade}`,
                }
              },
              input: {
                background: 'transparent',
                borderRadius: '0',
                // color: theme.palette.text.grey3,
                padding: '10px 14px 10px 0  !important',
                fontWeight: '400',
                ':placeholder': {
                  opacity: 1
                  // color: theme.palette.text.grey3,
                }
              },
              '.MuiSvgIcon-root path': {
                fill: theme.palette.grey[900]
              },
              '.MuiOutlinedInput-notchedOutline': {
                // borderColor: theme.palette.primary.mainFade,
                borderRadius: '8px'
              }
            }
          },

          '.MuiButton-outlinedPrimary': {
            // borderColor: theme.palette.primary.mainFade,
          },

          '.MuiButtonBase-root': {
            borderRadius: '8px'
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiFormLabel-root': {
            position: 'static',
            transform: 'none',
            fontSize: '0.875rem',
            lineHeight: '1.42857',
            marginBottom: '4px',
            fontWeight: '500',
            fontFamily: 'Inter',
            // color: theme.palette.grey.dark,
            color: theme.palette.grey.dark,
            '&.Mui-focused': {
              color: theme.palette.primary.dark
            }
          },

          '.MuiInputBase-root': {
            // height: "60px",
            borderRadius: '16px',
            padding: '5px 14px',
            border: `1px solid ${theme.palette.grey.dark}`,
            background: theme.palette.background.paper
          },

          '.MuiInputBase-input': {
            padding: '0 !important',
            height: '48px',
            background: theme.palette.background.paper,
            color: theme.palette.primary.dark,
            fontSize: '1rem',
            lineHeight: '1.25'
          },

          '.MuiOutlinedInput-notchedOutline': {
            display: 'none',
            legend: {
              display: 'none'
            }
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center'
        },
        outlined: {
          border: '1px dashed'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiSelect-select': {
            padding: '12.5px 20px 12.5px 0 !important'
            // fontWeight: "700",
          },
          '& > label': {
            // fontWeight: "500",
          },
          '&.therapist-note': {
            '.MuiOutlinedInput-root': {
              padding: '7px'
            },
            '.MuiOutlinedInput-input': {
              background: 'transparent !important',
              minHeight: '48px !important',
              color: '#000936',
              fontSize: 16,
              fontWeight: 400,
              lineHeight: '20px'
            }
          }
          // ".MuiOutlinedInput-root": {
          //   paddingLeft: "8px !important",
          //   borderRadius: "8px",
          //   borderColor: theme.palette.blue[500],

          //   ".MuiOutlinedInput-input": {
          //     height: "43px",
          //     background: "none",
          //   },
          // },
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            '&:hover': {
              backgroundColor: menuSelectedBack
            },
            '& .MuiListItemIcon-root': {
              color: menuSelected
            }
          },
          '&:hover': {
            backgroundColor: menuSelectedBack,
            color: menuSelected,
            '& .MuiListItemIcon-root': {
              color: menuSelected
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          minWidth: '36px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.dark
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.palette.text.dark,
          '&::placeholder': {
            color: theme.palette.text.secondary,
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: outlinedFilled ? bgColor : 'transparent',
          borderRadius: `${borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: mode === 'dark' ? theme.palette.text.primary + 28 : theme.palette.grey[400]
          },
          '&:hover $notchedOutline': {
            borderColor: theme.palette.primary.light
          },
          '&.MuiInputBase-multiline': {
            padding: 1
          }
        },
        input: {
          fontWeight: 500,
          background: outlinedFilled ? bgColor : 'transparent',
          padding: '15.5px 14px',
          borderRadius: `${borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0
            }
          }
        },
        inputAdornedStart: {
          paddingLeft: 4
        },
        notchedOutline: {
          borderRadius: `${borderRadius}px`
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: mode === 'dark' ? theme.palette.text.primary + 50 : theme.palette.grey[300]
          }
        },
        mark: {
          backgroundColor: theme.palette.background.paper,
          width: '4px'
        },
        valueLabel: {
          color: mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-tag': {
            background:
              mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.secondary.light,
            borderRadius: 4,
            color: theme.palette.text.dark,
            '.MuiChip-deleteIcon': {
              color:
                mode === 'dark' ? theme.palette.text.primary + 80 : theme.palette.secondary[200]
            }
          }
        },
        popper: {
          borderRadius: `${borderRadius}px`,
          boxShadow:
            '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: mode === 'dark' ? 0.2 : 1
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <IconCheckbox />,
        checkedIcon: <IconCheckboxChecked />
      },
      styleOverrides: {
        root: {
          /** checked not prop
           *"&.Mui-checked": {
           *    fontSize: "28px"
           *}
           */

          '& + .MuiFormControlLabel-label': {
            fontWeight: '400',
            color: theme.palette.grey[900],
            fontFamily: 'Inter'
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.dark,
          background: mode === 'dark' ? theme.palette.text.primary : theme.palette.primary[200]
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '5px 8px',
          minWidth: '73px',
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit'
          },
          '&.active, &.success': {
            background: '#B9F6CA'
          },
          '&.pending': {
            background: '#90CAF9'
          },
          '&.approved': {
            background: '#B39DDB'
          },
          '&.disabled': {
            background: '#D84315'
          }
        }
      }
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          fontSize: '16px'
        }
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14
        }
      }
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor: mode === 'dark' ? theme.palette.dark[900] : theme.palette.primary.light,
          '& .MuiTabs-flexContainer': {
            borderColor:
              mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.primary[200]
          },
          '& .MuiTab-root': {
            color: mode === 'dark' ? theme.palette.text.secondary : theme.palette.grey[900]
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.dark
          },
          '& .Mui-selected': {
            color: theme.palette.primary.dark
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '1px solid',
          borderColor: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.grey[200]
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '12px 0 12px 0'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // borderColor:
          //   mode === "dark"
          //     ? theme.palette.text.primary + 15
          //     : theme.palette.grey[200],
          borderColor: 'transparent',
          fontWeight: 500,
          paddingLeft: '5px',
          paddingRight: '5px',
          'text-align': 'left',

          '&.MuiTableCell-head': {
            fontSize: '0.75rem',
            paddingTop: '0',
            color: theme.palette.grey[500]
          },
          '&.MuiTableCell-body': {
            fontSize: '0.875rem',
            color: theme.palette.grey[800]
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          padding: '24px 20px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          background: theme.palette.text.primary
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem'
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: '0 !important',
          padding: '0 24px'
        },
        ul: {
          background: theme.palette.grey[50],
          display: 'inline-flex',
          padding: '0 12px',
          borderRadius: '12px',
          gap: '8px',
          li: {
            '.MuiPaginationItem-root': {
              borderRadius: '8px',
              fontSize: '1rem',
              fontWeight: '500',
              minWidth: '35px',
              color: '#1565C0',
              // color: theme.palette.grey[500],

              '&.MuiPaginationItem-previousNext': {
                width: '24px',
                height: '24px',
                minWidth: 0,
                border: '1px solid #1565C0',
                borderRadius: '50%',

                '&.Mui-disabled': {
                  borderColor: '#9e9e9e',

                  svg: {
                    path: {
                      fill: '#9e9e9e'
                    }
                  }
                },

                svg: {
                  path: {
                    fill: '#000'
                  }
                }
              },

              '&.Mui-selected': {
                // background: "#bdbdbd",
                background: '#90CAF9',
                color: theme.palette.background.paper
              }
            }
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          // padding: "0",
          marginRight: '5px',
          '.MuiSvgIcon-root': {
            path: {
              fill: theme.palette.blue[500]
            }
          }
        }
      }
    }
  };
}
