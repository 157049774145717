import { useNavigate } from 'react-router-dom';

// project imports
import { DASHBOARD_PATH } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') || '';
  const isBrowserVerified = useSelector((state: any) => state.auth.isBrowserVerified);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      if (isBrowserVerified) {
        navigate(DASHBOARD_PATH, { replace: true });
      }
    }
  }, [isLoggedIn, isBrowserVerified, navigate]);

  return children;
};

export default GuestGuard;
