import { useMutation, useQuery } from '@apollo/client';
import {
  CHANGE_PASSWORD,
  GET_AUTH_URL,
  GET_USER_PROFILE,
  UPDATE_ADMIN,
  UPLOAD_PROFILE_IMAGE
} from 'grapqhl';
import {
  Admin,
  AdminResponse,
  ChangePasswordDTO,
  ChangePasswordResponse,
  OTPAuthUrlResponse,
  PreSignedUrlResponse,
  UserProfileResponse,
  uploadProfileImageDTO
} from '../../../types/profile';

export const useGQL = () => {
  const ADMIN_CHANGE_PASSWORD = () =>
    useMutation<ChangePasswordDTO, ChangePasswordResponse>(CHANGE_PASSWORD);
  const GET_ADMIN_PROFILE = () => useQuery<UserProfileResponse>(GET_USER_PROFILE);
  const UPDATE_ADMIN_PROFILE = () =>
    useMutation<AdminResponse, { id: string; input: Partial<Admin> }>(UPDATE_ADMIN);
  const IMAGE_UPLOAD = () =>
    useMutation<PreSignedUrlResponse, { input: uploadProfileImageDTO }>(UPLOAD_PROFILE_IMAGE);
  const GET_OTP_AUTH_URL = (skipCondition) =>
    useQuery<OTPAuthUrlResponse>(GET_AUTH_URL, { skip: skipCondition });

  return {
    ADMIN_CHANGE_PASSWORD,
    GET_ADMIN_PROFILE,
    UPDATE_ADMIN_PROFILE,
    IMAGE_UPLOAD,
    GET_OTP_AUTH_URL
  };
};
