import React, { createContext, useState } from 'react';
import { LoginState } from 'types';

type GlobalContext = {
  loginState: LoginState | null;
  setLoginState: React.Dispatch<React.SetStateAction<LoginState | null>>;
};

export const AuthContext = createContext<GlobalContext | null>(null);

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [loginState, setLoginState] = useState<LoginState | null>(null);

  return (
    <AuthContext.Provider value={{ loginState, setLoginState }}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
