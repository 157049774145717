import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Avatar, CircularProgress } from '@mui/material';
import { GET_IMAGE_URL_FROM_OBJECT_ID } from 'grapqhl';

type ImageProps = {
  s3Key?: string;
  staticUrl?: string;
  variant?: 'circular' | 'rounded' | 'square';
  style?: React.CSSProperties;
};

const CustomAvatarComponents: React.FC<ImageProps> = ({ s3Key, staticUrl, variant, style }) => {
  const { refetch: fetchPreSignedUrl } = useQuery(GET_IMAGE_URL_FROM_OBJECT_ID, {
    skip: true
  });
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const formatImageUrl = async (imageKey: string) => {
    try {
      const s3Response = await fetchPreSignedUrl({ input: { objectKey: imageKey } });
      if (s3Response?.data?.getDocOrImgFromObjKey?.url) {
        return s3Response.data.getDocOrImgFromObjKey.url;
      }
      setError('Failed to fetch image URL');
    } catch (err) {
      setError('Error fetching image URL');
    }
    return null;
  };

  const fetchData = async () => {
    if (staticUrl) {
      setImageUrl(staticUrl);
    } else if (s3Key) {
      const url = await formatImageUrl(s3Key);
      if (url) {
        setImageUrl(url);
      }
    }
  };

  useEffect(() => {
    if (s3Key || staticUrl) {
      fetchData();
    }
  }, [s3Key, staticUrl]);

  if (error) {
    return <div>{error}</div>;
  }
  if (!imageUrl) {
    return <CircularProgress size={10} />;
  }
  return <Avatar variant={variant} sx={style} src={imageUrl} />;
};

export default CustomAvatarComponents;
