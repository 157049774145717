import { FormattedMessage } from 'react-intl';
import { IconUsers } from 'constants/icons';
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const userManagement: NavItemType = {
  id: 'User Management',
  title: <FormattedMessage id="User Management" />,
  icon: IconUsers,
  type: 'group',
  children: [
    {
      id: 'admin',
      title: 'Admin',
      type: 'item',
      url: '/admin/list',
      breadcrumbs: false,
      roles: ['admin', 'superAdmin']
    },
    {
      id: 'app-user',
      title: 'Therapists',
      type: 'item',
      url: '/app-user/list',
      breadcrumbs: false
    },
    {
      id: 'client-user',
      title: 'Clients',
      type: 'item',
      url: '/client-user/list',
      breadcrumbs: false
    }
  ]
};

export default userManagement;
