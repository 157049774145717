import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_IMAGE_URL_FROM_OBJECT_ID } from 'grapqhl';

type DocumentProps = {
  s3Key?: string; // Make s3Key optional
  staticUrl?: string;
};

const DocumentFileDownloader: React.FC<DocumentProps> = ({ s3Key, staticUrl }) => {
  const { refetch: fetchPreSignedUrl } = useQuery(GET_IMAGE_URL_FROM_OBJECT_ID, {
    skip: true
  });

  const [error, setError] = useState<string | null>(null);

  const formatImageUrl = async (imageKey: string) => {
    try {
      const s3Response = await fetchPreSignedUrl({
        input: {
          objectKey: imageKey
        }
      });
      if (s3Response?.data?.getDocOrImgFromObjKey?.url) {
        return s3Response.data.getDocOrImgFromObjKey.url;
      }
      setError('Error: Unable to fetch URL');
      return null;
    } catch (err) {
      setError('Error: Unable to fetch URL');
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let url = staticUrl;
      if (!staticUrl && s3Key) {
        url = await formatImageUrl(s3Key);
      }
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.download = url.split('/').pop() || 'document.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    fetchData();
  }, [s3Key, staticUrl]);

  if (error) {
    return <div>{error}</div>;
  }

  return null; // No need to render anything
};

export default DocumentFileDownloader;
