import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation ($input: LoginAdminDTO!) {
    login(input: $input) {
      admin {
        _id
        firstName
        lastName
        email
        role
        status
        permissions
      }
      refreshToken
      accessToken
      expiresBy
      expiresAt
    }
  }
`;

export const REGISTER = gql`
  mutation ($input: CreateAdminDTO!) {
    register(input: $input) {
      _id
      firstName
      lastName
      email
      status
      role
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($input: ForgotPasswordDTO!) {
    forgotPassword(input: $input) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($input: ResetPasswordDTO!) {
    resetPassword(input: $input)
  }
`;

export const VERIFY_TOKEN = gql`
  mutation ($input: ResetPasswordDTO!) {
    verifyToken(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($input: ChangePasswordDTO!) {
    changePassword(input: $input) {
      message
      status
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export const CODE_VERIFICATION = gql`
  mutation ($input: OtpVerificationFor2FADTO!) {
    otpVerificationFor2FA(input: $input) {
      admin {
        _id
        name
        email
        phone
        role
        status
      }
      refreshToken
      accessToken
    }
  }
`;
export const RESEND_OTP_CODE = gql`
  mutation ($input: Resend2FAOtpCodeDTO!) {
    resendOtpCode(input: $input) {
      expiresBy
      expiresAt
    }
  }
`;

export const UPLOAD_PROFILE_IMAGE = gql`
  mutation ($input: uploadProfileImageDTO!) {
    uploadProfileImage(input: $input) {
      url
    }
  }
`;
