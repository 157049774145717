/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */

import React, { useState, useEffect } from 'react';
import {
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Paper
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Spinner from 'components/spinner';
import FailureLoad from 'components/spinner/fail';

import useTable from './hooks/useTable';

import useDebouncedSearch from './hooks/useDebounceSearch';

import { headCells } from './constants/variables';
import { ArrangementOrder, TransactionFilter } from './constants/types';

import { getPageCount } from 'utils/pageCount';
import { IconFilter } from 'constants/icons';
import SearchIcon from '@mui/icons-material/Search';

import FilterModal from './components/filter-therapist';

import AppUserTableContainer from './components/table-container';
import { useDialog } from 'hooks/useDialogs';
import useGQL from 'hooks/useGQL';

// ==============================|| CUSTOMER LIST ||============================== //

const Client = () => {
  const theme = useTheme();
  const { TableContainer, EnhancedTableHead } = useTable();

  const [initialVal, setInitialVal] = useState<TransactionFilter>({
    status: [],
    dateCreated: { from: null, to: null },
    transactionType: []
  });

  const [filterArray, setFilterArray] = useState<TransactionFilter>({
    status: [],
    dateCreated: { from: null, to: null },
    transactionType: []
  });
  const [debouncedSearch] = useDebouncedSearch(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
      if (event) {
        setSearch(event?.target.value);
        setPage(1);
        handleRefetch();
      }
    }
  );

  const { TRANSACTION_LIST } = useGQL();
  const { error, loading, data, refetch } = TRANSACTION_LIST({
    input: {
      limit: 10,
      order: 'desc',
      orderBy: 'createdAt',
      searchText: '',
      skip: 0,
      userType: 'client',
      transactionFilter: filterArray
    }
  });

  const [order, setOrder] = useState<ArrangementOrder>('desc');
  const [orderBy, setOrderBy] = useState<string>('_id');
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>('');
  const [rows, setRows] = useState<any[]>([]);
  const [count, setCount] = useState<number>(1);
  const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({
    limit: 10,
    skip: 0
  });

  useEffect(() => {
    if (data?.getTransactionForAdmin?.data && data?.getTransactionForAdmin?.pagination) {
      setRows(data?.getTransactionForAdmin?.data);
      setCount(data?.getTransactionForAdmin?.pagination.total);
    }
  }, [data]);

  const handleRefetch = () => {
    refetch({
      input: {
        searchText: search,
        limit: pageMeta?.limit,
        skip: pageMeta?.skip,
        order,
        orderBy,
        userType: 'client',
        transactionFilter: filterArray
      }
    });
    setPage(1);
  };

  useEffect(() => {
    handleRefetch();
  }, [filterArray]);

  useEffect(() => {
    const limit = rowsPerPage;
    const skip = page > 0 ? limit * (page - 1) : 0;
    setPageMeta({ limit, skip });
    refetch({
      input: {
        searchText: search,
        limit,
        skip,
        order,
        orderBy,
        userType: 'client',
        transactionFilter: filterArray
      }
    });
  }, [rowsPerPage, page, search, filterArray]);

  const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const OpenFilterModal = () => {
    handleDialogOpen();
  };

  const closeDialog = () => {
    handleDialogClose();
  };

  return (
    <Stack rowGap={2}>
      <Paper sx={{ borderRadius: '12px' }}>
        <Stack
          direction={{ sm: 'row' }}
          justifyContent={{ sm: 'space-between' }}
          alignItems={{ sm: 'center' }}
          gap={1}
          sx={{ padding: 2 }}
        >
          <Typography variant="h5">Client Transaction History</Typography>

          <Stack direction="row" flexWrap="wrap" gap={1}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              onChange={debouncedSearch}
              placeholder="Search Therapist"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '8px',
                  borderRadius: '8px',
                  borderColor: theme.palette.blue[500]
                },

                '& .MuiOutlinedInput-input': {
                  height: '32px',
                  background: 'none'
                }
              }}
            />

            <IconButton
              onClick={OpenFilterModal}
              sx={{
                border: `1px solid ${theme.palette.blue[500]}`,
                borderRadius: '4px',
                padding: '7px',
                ':hover': {
                  borderColor: `1px solid ${theme.palette.blue[500]}`,
                  backgroundColor: theme.palette.blue[500],
                  color: theme.palette.background.paper,

                  'svg path': {
                    fill: theme.palette.background.paper
                  }
                }
              }}
            >
              <IconFilter />
            </IconButton>
          </Stack>
        </Stack>

        <FilterModal
          {...{ openDialog }}
          setFilterArray={setFilterArray}
          filterArray={filterArray}
          setInitialVal={setInitialVal}
          initialVal={initialVal}
          handleDialogClose={closeDialog}
        />
        {/* table */}

        {loading ? (
          <Spinner />
        ) : error ? (
          <FailureLoad />
        ) : (
          <>
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <EnhancedTableHead
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {rows.length == 0 ? (
                    <TableRow sx={{ borderBottom: 'none' }}>
                      <TableCell align="left"> </TableCell>
                      <TableCell align="left"> </TableCell>
                      <TableCell align="left"> </TableCell>
                      <TableCell align="left">
                        <Typography variant="h6">No Items</Typography>
                      </TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"> </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {rows.map((row, index) => (
                        <AppUserTableContainer key={index} row={row} index={index} />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Pagination
              sx={{ mt: '20px', mb: '20px' }}
              count={getPageCount(count, pageMeta.limit)}
              page={page}
              onChange={handleChangePage}
              color="secondary"
            />
          </>
        )}
      </Paper>
    </Stack>
  );
};

export default Client;
