import { Box } from '@mui/material';
import { Typography } from '@mui/material';

const DetailCard = ({ title, children }) => {
  return (
    <Box
      className="detail-card"
      sx={{
        border: '1px solid #E3F2FD',
        borderRadius: '12px'
      }}
    >
      {title && (
        <Typography
          variant="h2"
          component="h2"
          sx={{
            borderBottom: '1px solid #E3F2FD',
            padding: '15px 16px'
          }}
          fontSize="0.875rem"
        >
          {title}
        </Typography>
      )}
      <Box sx={{ padding: '24px 20px' }}>{children}</Box>
    </Box>
  );
};

export default DetailCard;
