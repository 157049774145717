import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type closeModalProps = {
  handleClose: () => void;
};

const CloseModal = ({ handleClose }: closeModalProps) => {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        top: '0',
        right: '0',
        border: 'none',
        width: '24px',
        height: '24px',
        minWidth: '0',
        minHeight: '0'
      }}
      onClick={handleClose}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseModal;
