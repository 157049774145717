import { useMutation, useQuery } from '@apollo/client';

import {
  FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  VERIFY_TOKEN,
  APP_USER_RESET_PASSWORD,
  SEND_RESET_PASSWORD_MAIL,
  GET_ADMIN_LIST,
  GET_ADMIN as ADMIN,
  UPDATE_ADMIN_STATUS as UAS,
  UPDATE_ADMIN_PERMISSION as UAP,
  UPDATE_ADMIN as ADMIN_UPDATE,
  DELETE_ADMIN as ADMIN_DELETE,
  GET_USER_PROFILE,
  UPLOAD_PROFILE_IMAGE as IMAGE_UPLOAD,
  UPLOAD_MENU_IMAGE as IMG_UPLOAD,
  CHANGE_PASSWORD,
  CREATE_PAGE as CREATE_PG,
  UPDATE_PAGE as UPDATE_PG,
  DELETE_PAGE as DELETE_PG,
  LIST_PAGE as GET_LIST_PAGE,
  LIST_PAGES as GET_LIST_PAGES,
  CREATE_MENU as CREATE_MENUS,
  LIST_MENUS as GET_LIST_MENUS,
  DELETE_MENU as MENU_DELETE,
  UPDATE_MENU as MENU_UPDATE,
  UPDATE_MENU_STATUS as MENU_STATUS_UPDATE,
  LIST_MENU as GET_LIST_MENU,
  CODE_VERIFICATION,
  RESEND_OTP_CODE,
  FIND_ALL_THERAPIST_PRACTICES as FATP,
  FIND_ALL_SPECIALITY as FASP,
  FIND_ALL_SERVICE as FAS,
  FIND_ALL_SESSION_COST as FASC,
  UPDATE_THERAPIST_PRACTICE_STATUS as UTPS,
  GET_ALL_THERAPIST_PRACTICE as GATP,
  CREATE_THERAPIST_PRACTICE as CTP,
  UPDATE_SERVICE_STATUS as USS,
  UPDATE_STATE_STATUS as USTS,
  UPDATE_COUNTRY_STATUS as UCS,
  UPDATE_SESSION_COST_STATUS as USCS,
  GET_ALL_SERVICE as GAS,
  GET_ALL_SESSION_COST as GASC,
  GET_ALL_LOCATION as GAL,
  CREATE_SERVICE as CS,
  CREATE_SESSION_COST as CSC,
  CREATE_LOCATION as CL,
  GET_ALL_REVIEW as GAR,
  GET_ALL_APPOINTMENTS as GAA,
  UPDATE_SPECIALITY_STATUS as USSP,
  GET_ALL_SPECIALITY as GASP,
  CREATE_SPECIALITY as CSP,
  GET_REVIEW as GR,
  UPDATE_REVIEW_STATUS as URS,
  DELETE_REVIEW as DR,
  SEND_ADMIN_RESET_PASSWORD_MAIL,
  GET_THERAPIST_SUBSCRIPTION_HISTORY as GTSH,
  DASHBOARD_DATA as DD,
  GET_ALL_SUBSCRIPTION_PRODUCTS as GASBP,
  TRANSACTION_LIST as TL,
  generateInvoiceByAdmin
} from '../grapqhl';
import { AdminDeleteResponse, AdminPermissionResponse, AdminStatusResponse } from 'types/profile';
import {
  CreateLocationDTO,
  CreateServiceDTO,
  CreateSessionCostDTO,
  CreateSpecialityDTO,
  CreateTherapistPracticeDTO,
  LocationResponse,
  ServiceResponse,
  SessionCostResponse,
  SpecialityResponse,
  TherapistPracticeResponse,
  UpdateCategoryStatusDTO,
  UpdateStateStatusDTO
} from 'types/categories';
import { AdminFilter } from 'views/userManagement/admins/constants/types';
import { PageFilter, PageStatus } from 'views/pageManagement/types';
import { GetReviewDTO, ReviewResponse } from 'views/reviews/constants/types';
import {
  getSubscriptionHistoryDTO,
  getSubscriptionProductDTO
} from 'views/userManagement/appUsers/constants/types';
import { getTransactionDTO } from 'views/transactionManagement/appUsers/constants/types';

type GetAdminListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
  filterArray?: AdminFilter;
};

type GetPagesDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
  status?: PageStatus | string;
  filterArray?: PageFilter;
};

type GetMenusDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetTherapistAppointmentDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
  userType?: string;
  userId?: string;
};

const useGQL = () => {
  const ADMIN_LOGIN = () => useMutation(LOGIN);
  const ADMIN_REGISTER = () => useMutation(REGISTER);
  const FORGOT_PASS = () => useMutation(FORGOT_PASSWORD);
  const RESET_PASS = () => useMutation(RESET_PASSWORD);
  const TOKEN_VERIFY = () => useMutation(VERIFY_TOKEN);
  const SEND_PASSWORD_RESET_MAIL = () => useMutation(SEND_RESET_PASSWORD_MAIL);

  const SEND_ADMIN_PASSWORD_RESET_MAIL = () => useMutation(SEND_ADMIN_RESET_PASSWORD_MAIL);
  const APP_USER_RESET_PASS = () => useMutation(APP_USER_RESET_PASSWORD);
  const ADMIN_CHANGE_PASSWORD = () => useMutation(CHANGE_PASSWORD);
  const CODE_VERIFY = () => useMutation(CODE_VERIFICATION);
  const RESEND_CODE = () => useMutation(RESEND_OTP_CODE);

  /* admins */
  const GET_ALL_ADMINS = (input: GetAdminListDTO = {}) =>
    useQuery(GET_ADMIN_LIST, { variables: { input } });
  const GET_ADMIN = (id: String) => useQuery(ADMIN, { variables: { id } });
  const UPDATE_ADMIN = () => useMutation(ADMIN_UPDATE);
  const DELETE_ADMIN = () => useMutation<AdminDeleteResponse>(ADMIN_DELETE);
  const GET_ADMIN_PROFILE = () => useQuery(GET_USER_PROFILE);
  const UPLOAD_PROFILE_IMAGE = () => useMutation(IMAGE_UPLOAD);
  const UPDATE_ADMIN_STATUS = () => useMutation<AdminStatusResponse, { id: string }>(UAS);
  const UPDATE_ADMIN_PERMISSION = () =>
    useMutation<AdminPermissionResponse, { id: string; permissionArray: string[] }>(UAP);

  const GET_ALL_APPOINTMENTS = (body: GetTherapistAppointmentDTO = {}) =>
    useQuery(GAA, { variables: { body } });
  const GET_ALL_REVIEW = () =>
    useQuery<ReviewResponse, GetReviewDTO>(GAR, {
      variables: { input: {} },
      notifyOnNetworkStatusChange: true
    });
  const GET_REVIEW = (getReviewId: String) => useQuery(GR, { variables: { getReviewId } });

  const UPDATE_REVIEW_STATUS = () => useMutation<ReviewResponse, { id: string }>(URS);
  const DELETE_REVIEW = () => useMutation<ReviewResponse>(DR);

  const CREATE_PAGE = () => useMutation(CREATE_PG);
  const UPDATE_PAGE = () => useMutation(UPDATE_PG);
  const DELETE_PAGE = () => useMutation(DELETE_PG);

  const LIST_PAGE = (id: String) => useQuery(GET_LIST_PAGE, { variables: { id } });
  const LIST_PAGES = (input: GetPagesDTO = {}) =>
    useQuery(GET_LIST_PAGES, {
      variables: { input },
      notifyOnNetworkStatusChange: true
    });

  //Categories

  const FIND_ALL_THERAPIST_PRACTICES = () =>
    useQuery<TherapistPracticeResponse>(FATP, {
      notifyOnNetworkStatusChange: true
    });

  const FIND_ALL_SPECIALITY = () =>
    useQuery<SpecialityResponse>(FASP, {
      notifyOnNetworkStatusChange: true
    });

  const FIND_ALL_SERVICE = () =>
    useQuery<ServiceResponse>(FAS, {
      notifyOnNetworkStatusChange: true
    });

  const FIND_ALL_SESSION_COST = () =>
    useQuery<SessionCostResponse>(FASC, {
      notifyOnNetworkStatusChange: true
    });

  const GET_ALL_THERAPIST_PRACTICE = (input: GetListDTO = {}) =>
    useQuery(GATP, { variables: { input } });

  const GET_ALL_SERVICE = (input: GetListDTO = {}) => useQuery(GAS, { variables: { input } });

  const GET_ALL_SESSION_COST = (input: GetListDTO = {}) => useQuery(GASC, { variables: { input } });

  const GET_ALL_LOCATION = (input: GetListDTO = {}) => useQuery(GAL, { variables: { input } });

  const GET_ALL_SPECIALITY = (input: GetListDTO = {}) => useQuery(GASP, { variables: { input } });

  const CREATE_THERAPIST_PRACTICE = () =>
    useMutation<TherapistPracticeResponse, CreateTherapistPracticeDTO>(CTP);

  const CREATE_SERVICE = () => useMutation<ServiceResponse, CreateServiceDTO>(CS);

  const CREATE_SESSION_COST = () => useMutation<SessionCostResponse, CreateSessionCostDTO>(CSC);

  const CREATE_LOCATION = () => useMutation<LocationResponse, CreateLocationDTO>(CL);
  const CREATE_SPECIALITY = () => useMutation<SpecialityResponse, CreateSpecialityDTO>(CSP);

  const UPDATE_SERVICE_STATUS = () => useMutation<ServiceResponse, { id: string }>(USS);

  const UPDATE_SESSION_COST_STATUS = () => useMutation<SessionCostResponse, { id: string }>(USCS);

  const UPDATE_COUNTRY_STATUS = () =>
    useMutation<LocationResponse, { input: UpdateCategoryStatusDTO }>(UCS);

  const UPDATE_STATE_STATUS = () =>
    useMutation<LocationResponse, { input: UpdateStateStatusDTO }>(USTS);

  const UPDATE_SPECIALITY_STATUS = () => useMutation<SpecialityResponse, { id: string }>(USSP);

  const UPDATE_THERAPIST_PRACTICE_STATUS = () =>
    useMutation<TherapistPracticeResponse, { id: string }>(UTPS);

  const CREATE_MENU = () => useMutation(CREATE_MENUS);
  const LIST_MENUS = (input: GetMenusDTO = {}) =>
    useQuery(GET_LIST_MENUS, {
      variables: { input },
      notifyOnNetworkStatusChange: true
    });
  const DELETE_MENU = () => useMutation(MENU_DELETE);
  const LIST_MENU = (id: String | undefined) =>
    useQuery(GET_LIST_MENU, { variables: { id }, fetchPolicy: 'network-only' });
  const UPDATE_MENU = () => useMutation(MENU_UPDATE);
  const UPDATE_MENU_STATUS = () => useMutation(MENU_STATUS_UPDATE);
  const UPLOAD_MENU_IMAGE = () => useMutation(IMG_UPLOAD);

  const GET_THERAPIST_SUBSCRIPTION_HISTORY = (body: getSubscriptionHistoryDTO) =>
    useQuery(GTSH, {
      variables: body,
      notifyOnNetworkStatusChange: true
    });

  const GET_ALL_SUBSCRIPTION_PRODUCTS = (body: getSubscriptionProductDTO) =>
    useQuery(GASBP, {
      variables: body,
      notifyOnNetworkStatusChange: true
    });

  const TRANSACTION_LIST = (input: getTransactionDTO) =>
    useQuery(TL, {
      variables: input,
      notifyOnNetworkStatusChange: true
    });

  const GenerateInvoiceByAdmin = () =>
    useMutation<
      { generateInvoiceByAdmin: { message: string; url: string } },
      { input: { id: string } }
    >(generateInvoiceByAdmin, { notifyOnNetworkStatusChange: true });

  const DASHBOARD_DATA = () => useQuery(DD);
  return {
    UPDATE_ADMIN_STATUS,
    ADMIN_LOGIN,
    ADMIN_REGISTER,
    FORGOT_PASS,
    RESET_PASS,
    TOKEN_VERIFY,
    SEND_PASSWORD_RESET_MAIL,
    APP_USER_RESET_PASS,
    GET_ALL_ADMINS,
    GET_ADMIN,
    UPDATE_ADMIN,
    DELETE_ADMIN,
    GET_ADMIN_PROFILE,
    ADMIN_CHANGE_PASSWORD,
    CREATE_PAGE,
    UPDATE_PAGE,
    DELETE_PAGE,
    LIST_PAGE,
    CREATE_MENU,
    LIST_MENUS,
    DELETE_MENU,
    LIST_MENU,
    UPDATE_MENU,
    UPDATE_MENU_STATUS,
    UPLOAD_MENU_IMAGE,
    CODE_VERIFY,
    RESEND_CODE,
    UPLOAD_PROFILE_IMAGE,
    LIST_PAGES,
    FIND_ALL_THERAPIST_PRACTICES,
    UPDATE_THERAPIST_PRACTICE_STATUS,
    GET_ALL_THERAPIST_PRACTICE,
    CREATE_THERAPIST_PRACTICE,
    UPDATE_SERVICE_STATUS,
    UPDATE_SPECIALITY_STATUS,
    CREATE_SERVICE,
    CREATE_SPECIALITY,
    GET_ALL_SERVICE,
    GET_ALL_SPECIALITY,
    FIND_ALL_SERVICE,
    FIND_ALL_SPECIALITY,
    UPDATE_ADMIN_PERMISSION,
    GET_ALL_REVIEW,
    GET_REVIEW,
    DELETE_REVIEW,
    UPDATE_REVIEW_STATUS,
    SEND_ADMIN_PASSWORD_RESET_MAIL,
    GET_ALL_APPOINTMENTS,
    GET_ALL_SESSION_COST,
    FIND_ALL_SESSION_COST,
    UPDATE_SESSION_COST_STATUS,
    CREATE_SESSION_COST,
    GET_ALL_LOCATION,
    CREATE_LOCATION,
    UPDATE_COUNTRY_STATUS,
    UPDATE_STATE_STATUS,
    GET_THERAPIST_SUBSCRIPTION_HISTORY,
    DASHBOARD_DATA,
    GET_ALL_SUBSCRIPTION_PRODUCTS,
    TRANSACTION_LIST,
    GenerateInvoiceByAdmin
  };
};

export default useGQL;
