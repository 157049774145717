import { gql } from '@apollo/client';

export const GET_ALL_REVIEW = gql`
  query GetAllReview($input: GetReviewDTO!) {
    getAllReview(input: $input) {
      reviews {
        _id
        appointmentId
        client {
          firstName
          lastName
          profileImage
          pronoun
        }
        clientId
        createdAt
        rating
        reviewText
        status
        isAnonymous
        therapist {
          firstName
          lastName
          profileImage
          title
        }
        therapistId
        updatedAt
      }
      pagination {
        hasNextPage
        total
      }
      averageRating
    }
  }
`;

export const GET_REVIEW = gql`
  query GetReview($getReviewId: String!) {
    getReview(id: $getReviewId) {
      review {
        _id
        appointmentId
        therapistId
        isAnonymous
        clientId
        client {
          firstName
          lastName
          profileImage
          pronoun
        }
        therapist {
          firstName
          lastName
          profileImage
          title
        }
        createdAt
        updatedAt
        rating
        reviewText
        status
      }
    }
  }
`;
