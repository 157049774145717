import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
// Maintenance routes
const ErrorPage = Loadable(lazy(() => import('../views/pages/maintenance/error')));
const UnderConstruction = Loadable(
  lazy(() => import('../views/pages/maintenance/under-construction'))
);
const ComingSoon = Loadable(lazy(() => import('../views/pages/maintenance/coming-soon')));

const maintenanceRoutes = [
  {
    path: 'pages',
    children: [
      {
        path: 'coming-soon',
        element: <ComingSoon />
      },
      {
        path: 'under-construction',
        element: <UnderConstruction />
      }
    ]
  },
  {
    path: '*',
    element: <ErrorPage />
  }
];

export default maintenanceRoutes;
