import { useEffect } from 'react';

import * as Yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  FormHelperText,
  Autocomplete
} from '@mui/material';
import CloseModal from 'components/closeModal';

import { Formik } from 'formik';
import { initialValues, locationInitialValues, serviceInitialValues } from 'constants/categories';
import { FormInputServiceType, FormInputType, LocationFormInputType } from 'types/categories';

import useSnackbar from 'hooks/common/useSnackbar';
import useGQL from 'hooks/useGQL';
import { australiaStateArray, countries, newZelandStateArray } from 'constants/country';

type filterModalProps = {
  openDialog: boolean;
  refetch: any;
  serviceRefetch: any;
  specialityRefetch: any;
  sessionCostRefetch: any;
  locationResponseRefetch: any;
  therapistOpen: boolean;
  serviceOpen: boolean;
  specialityOpen: boolean;
  setTherapistOpen: any;
  setServiceOpen: any;
  setSpecialityOpen: any;
  sessionCostOpen: any;
  setSessionCostOpen: any;
  locationOpen: any;
  setLocationOpen: any;

  handleDialogClose: () => void;
};

const AddTherapistModal = ({
  openDialog,
  refetch,
  serviceRefetch,
  specialityRefetch,
  sessionCostRefetch,
  locationResponseRefetch,
  therapistOpen,
  serviceOpen,
  specialityOpen,
  sessionCostOpen,
  setSessionCostOpen,
  locationOpen,
  setLocationOpen,

  handleDialogClose
}: filterModalProps) => {
  const { handleOpenSnackbar } = useSnackbar();
  const {
    CREATE_THERAPIST_PRACTICE,
    CREATE_SERVICE,
    CREATE_SPECIALITY,
    CREATE_SESSION_COST,
    CREATE_LOCATION
  } = useGQL();
  const [handleCreateTherapistPractice, { data }] = CREATE_THERAPIST_PRACTICE();

  const [handleCreateService, { data: serviceData }] = CREATE_SERVICE();
  const [handleCreateSessionCost, { data: sessionCostData }] = CREATE_SESSION_COST();
  const [handleCreateLocation, { data: locationData }] = CREATE_LOCATION();

  const [handleCreateSpeciality, { data: specialityData }] = CREATE_SPECIALITY();

  const handleSpecialityFormSubmit = async (
    values: FormInputType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    try {
      await handleCreateSpeciality({
        variables: {
          input: { ...values }
        }
      });
      handleDialogClose();
    } catch (error: any) {
      handleOpenSnackbar({ message: error.message, alertType: 'error' });
      setSubmitting(false);
    }
  };

  const handleServiceFormSubmit = async (
    values: FormInputServiceType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    try {
      await handleCreateService({
        variables: {
          input: { ...values }
        }
      });
      handleDialogClose();
    } catch (error: any) {
      handleOpenSnackbar({ message: error.message, alertType: 'error' });
      setSubmitting(false);
    }
  };

  const handleSessionCostFormSubmit = async (
    values: FormInputType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    try {
      await handleCreateSessionCost({
        variables: {
          input: { title: `Upto AUD ${values.title}`, value: parseFloat(values.title) }
        }
      });
      handleDialogClose();
    } catch (error: any) {
      handleOpenSnackbar({ message: error.message, alertType: 'error' });
      setSubmitting(false);
    }
  };

  const handleLocationFormSubmit = async (
    values: LocationFormInputType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    try {
      await handleCreateLocation({
        variables: {
          input: {
            country: { title: values.country, status: 'hidden' },
            state: { title: values.state, status: 'hidden' }
          }
        }
      });
      handleDialogClose();
    } catch (error: any) {
      handleOpenSnackbar({ message: error.message, alertType: 'error' });
      setSubmitting(false);
    }
  };
  const handleFormSubmit = async (
    values: FormInputType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    try {
      await handleCreateTherapistPractice({
        variables: {
          input: { ...values }
        }
      });
      handleDialogClose();
    } catch (error: any) {
      handleOpenSnackbar({ message: error.message, alertType: 'error' });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (data?.createTherapistPractice) {
      handleOpenSnackbar({
        message: data?.createTherapistPractice.message,
        alertType: 'success'
      });
      refetch();
    }

    if (specialityData?.createSpeciality) {
      handleOpenSnackbar({
        message: specialityData?.createSpeciality?.message,
        alertType: 'success'
      });
      specialityRefetch();
    }
    if (serviceData?.createService) {
      handleOpenSnackbar({
        message: serviceData?.createService.message,
        alertType: 'success'
      });
      serviceRefetch();
    }

    if (sessionCostData?.createSessionCost) {
      handleOpenSnackbar({
        message: sessionCostData?.createSessionCost.message,
        alertType: 'success'
      });
      sessionCostRefetch();
    }

    if (locationData?.createLocation) {
      handleOpenSnackbar({
        message: locationData?.createLocation.message,
        alertType: 'success'
      });
      locationResponseRefetch();
    }
  }, [data, serviceData, specialityData, sessionCostData, locationData]);

  const getStateOptions = (countryName: string) => {
    if (countryName === 'Australia') {
      return australiaStateArray;
    }

    if (countryName === 'New Zealand') {
      return newZelandStateArray;
    }
    return [{ code: '', label: '' }];
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      className="filter-dialog"
      sx={{
        '.MuiDialog-paper': {
          padding: '0',
          maxWidth: '638px',
          borderRadius: '4px',

          '@media(min-width:768px)': {
            minWidth: '620px'
          }
        }
      }}
    >
      {openDialog && therapistOpen && (
        <Box
          sx={{
            padding: '24px',
            '& .MuiDialogTitle-root': {
              padding: '0 24px 0 0'
            }
          }}
        >
          <DialogTitle sx={{ position: 'relative' }}>
            Therapist
            <CloseModal handleClose={handleDialogClose} />
          </DialogTitle>
          <Divider sx={{ borderColor: '#E9E1D5', margin: '24px 0' }} />
          <DialogContent sx={{ padding: '0' }}>
            <Stack>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  title: Yup.string()
                    .min(3, 'Title must be at least 3 characters')
                    .matches(/^\S.*$/, 'First characters cannot be spaces')
                    .matches(
                      /^(?:[A-Z][a-z]*\s?)+$/,
                      'Each word must start with an uppercase letter'
                    )
                    .max(30, 'Title must be at most 30 characters')
                    .required('Title is required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                  handleFormSubmit(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack direction="column" gap="22px">
                      <TextField
                        fullWidth
                        id="title"
                        value={values.title}
                        name="title"
                        label="Title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.title && errors.title && (
                        <FormHelperText error id="title-error">
                          {errors.title}
                        </FormHelperText>
                      )}

                      <Box>
                        <Button type="submit" variant="contained" fullWidth color="primary">
                          {isSubmitting ? 'Submitting' : 'Add therapist'}
                        </Button>
                      </Box>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </DialogContent>
        </Box>
      )}

      {openDialog && serviceOpen && (
        <Box
          sx={{
            padding: '24px',
            '& .MuiDialogTitle-root': {
              padding: '0 24px 0 0'
            }
          }}
        >
          <DialogTitle sx={{ position: 'relative' }}>
            Service
            <CloseModal handleClose={handleDialogClose} />
          </DialogTitle>
          <Divider sx={{ borderColor: '#E9E1D5', margin: '24px 0' }} />
          <DialogContent sx={{ padding: '0' }}>
            <Stack>
              <Formik
                enableReinitialize
                initialValues={serviceInitialValues}
                validationSchema={Yup.object().shape({
                  title: Yup.string()
                    .min(3, 'Title must be at least 3 characters')
                    .matches(/^\S.*$/, 'First characters cannot be spaces')
                    .matches(
                      /^(?:[A-Z][a-z]*\s?)+$/,
                      'Each word must start with an uppercase letter'
                    )
                    .max(30, 'Title must be at most 30 characters')
                    .required('Title is required'),

                  programCode: Yup.string().required('Program code is required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                  handleServiceFormSubmit(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack direction="column" gap="22px">
                      <TextField
                        fullWidth
                        id="title"
                        value={values.title}
                        name="title"
                        label="Title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.title && errors.title && (
                        <FormHelperText error id="title-error">
                          {errors.title}
                        </FormHelperText>
                      )}

                      <TextField
                        fullWidth
                        id="programCode"
                        value={values.programCode}
                        name="programCode"
                        label="Program Code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.programCode && errors.programCode && (
                        <FormHelperText error id="title-error">
                          {errors.programCode}
                        </FormHelperText>
                      )}

                      <Box>
                        <Button type="submit" variant="contained" fullWidth color="primary">
                          {isSubmitting ? 'Submitting' : 'Add Service'}
                        </Button>
                      </Box>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </DialogContent>
        </Box>
      )}

      {openDialog && sessionCostOpen && (
        <Box
          sx={{
            padding: '24px',
            '& .MuiDialogTitle-root': {
              padding: '0 24px 0 0'
            }
          }}
        >
          <DialogTitle sx={{ position: 'relative' }}>
            Hourly session cost
            <CloseModal handleClose={handleDialogClose} />
          </DialogTitle>
          <Divider sx={{ borderColor: '#E9E1D5', margin: '24px 0' }} />
          <DialogContent sx={{ padding: '0' }}>
            <Stack>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  title: Yup.number()
                    .min(50, 'Hourly session cost must be at least minimum 50')
                    .max(1000, 'Hourly session cost must be at most 1000')
                    .required('Hourly session cost is required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                  handleSessionCostFormSubmit(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack direction="column" gap="22px">
                      <TextField
                        fullWidth
                        type="number"
                        id="title"
                        value={values.title}
                        name="title"
                        label="Hourly session cost"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.title && errors.title && (
                        <FormHelperText error id="title-error">
                          {errors.title}
                        </FormHelperText>
                      )}

                      <Box>
                        <Button type="submit" variant="contained" fullWidth color="primary">
                          {isSubmitting ? 'Submitting' : 'Add Hourly session cost'}
                        </Button>
                      </Box>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </DialogContent>
        </Box>
      )}

      {openDialog && locationOpen && (
        <Box
          sx={{
            padding: '24px',
            '& .MuiDialogTitle-root': {
              padding: '0 24px 0 0'
            }
          }}
        >
          <DialogTitle sx={{ position: 'relative' }}>
            Location
            <CloseModal handleClose={handleDialogClose} />
          </DialogTitle>
          <Divider sx={{ borderColor: '#E9E1D5', margin: '24px 0' }} />
          <DialogContent sx={{ padding: '0' }}>
            <Stack>
              <Formik
                enableReinitialize
                initialValues={locationInitialValues}
                validationSchema={Yup.object().shape({
                  country: Yup.string().required('Country is required'),
                  state: Yup.string().required('State of selected country is required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                  handleLocationFormSubmit(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack direction="column" gap="22px">
                      <Autocomplete
                        id="country-select-demo"
                        fullWidth
                        options={countries}
                        filterOptions={(options) =>
                          options.filter((option) => option.code === 'AU' || option.code === 'NZ')
                        }
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        onChange={(e, value) => {
                          setFieldValue('state', '');
                          setFieldValue('country', value?.label);
                          console.log(values.state, 'values.state');
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a country"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password' // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      {touched.country && errors.country && (
                        <FormHelperText error id="title-error">
                          {errors.country}
                        </FormHelperText>
                      )}

                      <Autocomplete
                        id="country-select-demo"
                        fullWidth
                        options={getStateOptions(values.country)}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        onChange={(e, value) => {
                          setFieldValue('state', value?.label);
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={values.state}
                            label="Choose a state"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password' // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      {touched.state && errors.state && (
                        <FormHelperText error id="title-error">
                          {errors.state}
                        </FormHelperText>
                      )}

                      <Box>
                        <Button type="submit" variant="contained" fullWidth color="primary">
                          {isSubmitting ? 'Submitting' : 'Add Location'}
                        </Button>
                      </Box>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </DialogContent>
        </Box>
      )}

      {openDialog && specialityOpen && (
        <Box
          sx={{
            padding: '24px',
            '& .MuiDialogTitle-root': {
              padding: '0 24px 0 0'
            }
          }}
        >
          <DialogTitle sx={{ position: 'relative' }}>
            Speciality
            <CloseModal handleClose={handleDialogClose} />
          </DialogTitle>
          <Divider sx={{ borderColor: '#E9E1D5', margin: '24px 0' }} />
          <DialogContent sx={{ padding: '0' }}>
            <Stack>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  title: Yup.string()
                    .min(3, 'Title must be at least 3 characters')
                    .matches(/^\S.*$/, 'First characters cannot be spaces')
                    .matches(
                      /^(?:[A-Z][a-z]*\s?)+$/,
                      'Each word must start with an uppercase letter'
                    )
                    .max(30, 'Title must be at most 30 characters')
                    .required('Title is required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                  handleSpecialityFormSubmit(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack direction="column" gap="22px">
                      <TextField
                        fullWidth
                        id="title"
                        value={values.title}
                        name="title"
                        label="Title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.title && errors.title && (
                        <FormHelperText error id="title-error">
                          {errors.title}
                        </FormHelperText>
                      )}

                      <Box>
                        <Button type="submit" variant="contained" fullWidth color="primary">
                          {isSubmitting ? 'Submitting' : 'Add speciality'}
                        </Button>
                      </Box>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </DialogContent>
        </Box>
      )}
    </Dialog>
  );
};
export default AddTherapistModal;
