import { useSelector } from 'react-redux';
import { ACTION } from 'views/userManagement/admins/constants/variables';

const usePermissionRoles = () => {
  const { user } = useSelector((state: any) => state.auth);

  const IsEditAdmin: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.ADMIN_EDIT)));

  const IsEditAdminAccess: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.ADMIN_ACCESS_EDIT)));
  const IsAddAdmin: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.ADMIN_ADD)));

  const IsDeleteAdmin: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.ADMIN_DELETE)));

  const IsEnableDisableAdmin: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.ADMIN_ENABLE_DISABLE)));

  const IsDeleteClient: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.CLIENT_DELETE)));

  const IsEnableDisableClient: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.CLIENT_ENABLE_DISABLE)));

  const IsViewClientDetail: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.VIEW_CLIENT_DETAIL)));
  const IsApproveDeclineTherapist: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.THERAPIST_APPROVE_DECLINE)));
  const IsDeleteTherapist: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.THERAPIST_DELETE)));
  const IsEnableDisableTherapist: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.THERAPIST_ENABLE_DISABLE)));
  const IsViewTherapistDetail: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.VIEW_THERAPIST_DETAIL)));
  const IsEditEmailTemplate: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.EMAIL_TEMPLATE_MANAGEMENT_EDIT)));

  const IsEditPageManagement: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.PAGE_MANAGEMENT_EDIT)));
  const IsListReview: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.REVIEW_LIST)));
  const IsListTransaction: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.TRANSACTION_LIST)));
  const IsEditCategory: boolean =
    user &&
    (user.role === 'superAdmin' ||
      (user.role === 'admin' && user.permissions.includes(ACTION.CATEGORY_EDIT)));

  return {
    IsEditAdmin,
    IsEditAdminAccess,
    IsAddAdmin,
    IsDeleteAdmin,
    IsEnableDisableAdmin,
    IsDeleteClient,
    IsEnableDisableClient,
    IsViewClientDetail,
    IsApproveDeclineTherapist,
    IsDeleteTherapist,
    IsEnableDisableTherapist,
    IsViewTherapistDetail,
    IsEditEmailTemplate,
    IsEditPageManagement,
    IsListReview,
    IsListTransaction,
    IsEditCategory
  };
};

export default usePermissionRoles;
