import { gql } from '@apollo/client';

export const GET_ALL_THERAPIST_PRACTICE = gql`
  query Query($input: GetTherapistPracticeDTO!) {
    getAllTherapistPractices(input: $input) {
      message
      pagination {
        hasNextPage
        total
      }
      therapistPractices {
        _id
        createdAt
        status
        title
        updatedAt
      }
    }
  }
`;

export const GET_ALL_SPECIALITY = gql`
  query Query($input: GetSpecialityDTO!) {
    getAllSpecialities(input: $input) {
      message
      pagination {
        hasNextPage
        total
      }
      specialitys {
        _id
        createdAt
        status
        title
        updatedAt
      }
    }
  }
`;

export const GET_ALL_SERVICE = gql`
  query Query($input: GetServiceDTO!) {
    getAllServices(input: $input) {
      message
      pagination {
        hasNextPage
        total
      }
      services {
        _id
        createdAt
        status
        title
        updatedAt
      }
    }
  }
`;

export const GET_ALL_SESSION_COST = gql`
  query Query($input: GetSessionCostDTO!) {
    getAllSessionCosts(input: $input) {
      message
      pagination {
        hasNextPage
        total
      }
      sessionCosts {
        _id
        createdAt
        status
        value
        title
        updatedAt
      }
    }
  }
`;

export const GET_ALL_LOCATION = gql`
  query Query($input: GetLocationDTO!) {
    getAllLocations(input: $input) {
      message
      pagination {
        hasNextPage
        total
      }
      locations {
        _id
        createdAt
        country {
          title
          status
        }
        state {
          title
          status
        }
        updatedAt
      }
    }
  }
`;

export const FIND_ALL_THERAPIST_PRACTICES = gql`
  query FindAllTherapistPractices {
    findAllTherapistPractices {
      message
      therapistPractices {
        _id
        createdAt
        title
        status
        updatedAt
      }
    }
  }
`;

export const FIND_ALL_SPECIALITY = gql`
  query FindAllSpecialitys {
    findAllSpecialitys {
      message
      specialitys {
        _id
        createdAt
        status
        title
        updatedAt
      }
    }
  }
`;

export const FIND_ALL_SERVICE = gql`
  query FindAllServices {
    findAllServices {
      message
      services {
        _id
        createdAt
        status
        title
        updatedAt
      }
    }
  }
`;

export const FIND_ALL_SESSION_COST = gql`
  query FindAllSessionCosts {
    findAllSessionCosts {
      message
      sessionCosts {
        _id
        createdAt
        status
        title
        updatedAt
      }
    }
  }
`;
