import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';
import { IconEmail } from 'constants/icons';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const emailTemplateManagement: NavItemType = {
  id: 'email-template',
  title: <FormattedMessage id="Email template Management" />,
  icon: IconEmail,
  type: 'group',
  url: '/email-template/list'
};

export default emailTemplateManagement;
